import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput from "../common/DatePickerInput";


const RunPayroll =()=> {
    const [startDate, setStartDate] = useState(new Date());

    const data_ = [
        {
            id: "1231-3213123",
            fName: "andrew",
            lName: "ebong",
            email: "ebongandrew@clairebutton.com",
            earnings: 900000,
            localDeductions: 300000,
            netPay: 600000
        },
        {
            id: "1231-3213122",
            fName: "glander",
            lName: "madondo",
            email: "glander.m@clairebutton.com",
            earnings: 1200000,
            localDeductions: 200000,
            netPay: 1000000
        },
        {
            id: "1231-3213125",
            fName: "laura",
            lName: "kemiteto",
            email: "laurak@clairebutton.com",
            earnings: 1900000,
            localDeductions: 300000,
            netPay: 1600000
        },
        {
            id: "1231-3213129",
            fName: "michael",
            lName: "noah",
            email: "michael.n@clairebutton.com",
            earnings: 2900000,
            localDeductions: 300000,
            netPay: 2600000
        },
        {
            id: "1231-321312323",
            fName: "conrad",
            lName: "amai",
            email: "conrad.a@clairebutton.com",
            earnings: 1900000,
            localDeductions: 300000,
            netPay: 1600000
        }
    ];

    const renderEmployees =()=> {
        let data = data_;
        let lastItemId = data_[data.length - 1].id;
        return data.map(item =>
            <div className={'listColT horizontalFlex'}>
                <div className={'flex1'}>
                    <span className={'listColText'}>{item.id}</span>
                </div>
                <div className={'flex1 listColtT'}>
                    <span className={'listColText'}>{item.fName} {item.lName}</span>
                </div>
                <div className={'flex1 listColtT'}>
                    <span className={'listColText'}>{item.email}</span>
                </div>
                <div className={'flex1 listColtT'}>
                    <span className={'listColText'}>{item.earnings}</span>
                </div>
                <div className={'flex1 listColtT'}>
                    <span className={'listColText'}>{item.localDeductions}</span>
                </div>
                <div className={'flex1 listColtT'}>
                    <span className={'listColText'}>{item.netPay}</span>
                </div>
            </div>
        );
    }

    return(
        <div>
            {/*<div className={'runPayrollHeader'}>*/}
            {/*    <div className={'runPayrollHeaderLeft'}>*/}
            {/*        <div className={'runPayrollHeaderPeriod'}>Pay Period [1 month]:</div>*/}
            {/*        <div className={'runPayrollPeriodHolder'}>*/}
            {/*            <div className={'runPayrollPeriodHolderStart'}>*/}
            {/*                Start:*/}
            {/*            </div>*/}
            {/*            <div className={'runPayrollPeriodHolderEnd'}>*/}
            {/*                End:*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className={'runPayrollHeaderRight'}>*/}
            {/*        <div>*/}
            {/*            <div className={'runPayrollHeaderEmployeeEst'}>Est</div>*/}
            {/*            <div className={'runPayrollHeaderAmount'}>*/}
            {/*                <div className={'runPayrollHeaderCurrency'}>Shs</div>*/}
            {/*                40,000,000</div>*/}
            {/*            <div className={'runPayrollHeaderEmployeeCount'}>50 Employees</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div style={{padding:"5px", backgroundColor:"#f1f1f1", borderRadius: "4px", marginBottom:"10px", color:"#164CB2",
            border:"1px solid #e8e8e8"
            }}>
                <span style={{ fontWeight:"bold"}}>Prev Payroll </span> <span style={{color:"#aaa"}}>|</span>  Period: 12/05/2023 - 12/31/20023. Count: 5. Amount: 12,000,000
            </div>
            <div className={'runPayrollHeader'}>


                <div className={'runPayrollHeaderLeft'}>
                    {/*<div className={'runPayrollHeaderPeriod'}>Pay Period [1 month]:</div>*/}
                    {/*<div className={'runPayrollPeriodHolder'} style={{borderRadius:"4px"}}>*/}
                    {/*    <div className={'runPayrollPeriodHolderStart'}>*/}
                    {/*        Start:*/}
                    {/*    </div>*/}
                    {/*    <div className={'runPayrollPeriodHolderEnd'}>*/}
                    {/*        End:*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={'runPayrollButtonContainer'} style={{justifyContent:"left"}}>
                        {/*<div className={'runPayrollButton'}>Run Payroll</div>*/}
                        <div className={'formSubmit-blue'} style={{borderRadius: "6px"}}>Run Payroll</div>
                    </div>
                </div>

                <div className={'runPayrollHeaderRight'}>

                    <div className={'runPayrollHeaderPeriod'}>Pay Period: [1 month]</div>
                    <div className={'runPayrollPeriodHolder'} style={{borderRadius:"4px"}}>
                        <div className={'runPayrollPeriodHolderStart'} style={{position:'relative'}}>
                            <div style={{width:'50px', display:'flex',justifyContent:'center'}}>Start:</div>
                            <div style={{flex:1, display:'flex'}}>

                            </div>
                            <div style={{position:'absolute', top:'50px', left:'0px', zIndex:'100'}}>
                                {/*<DatePickerInput/>*/}
                            </div>
                        </div>
                        <div className={'runPayrollPeriodHolderEnd'} style={{position:'relative'}}>
                            End:
                            <div style={{position:'absolute', top:'50px', left:'0px'}}>
                                {/*<DatePickerInput/>*/}
                            </div>
                        </div>
                    </div>
                    {/*<div className={'runPayrollButtonContainer'}>*/}
                    {/*    /!*<div className={'runPayrollButton'}>Run Payroll</div>*!/*/}
                    {/*    <div className={'formSubmit-blue'} style={{borderRadius: "6px"}}>Run Payroll</div>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <div className={'runPayrollHeaderEmployeeEst'}>Est</div>*/}
                    {/*    <div className={'runPayrollHeaderAmount'}>*/}
                    {/*        <div className={'runPayrollHeaderCurrency'}>Shs</div>*/}
                    {/*        40,000,000</div>*/}
                    {/*    <div className={'runPayrollHeaderEmployeeCount'}>50 Employees</div>*/}
                    {/*</div>*/}
                </div>
            </div>

            {/*<div className={'runPayrollButtonContainer'}>*/}
            {/*    <div className={'runPayrollButton'}>Run Payroll</div>*/}
            {/*    <div className={'formSubmit-blue'} style={{borderRadius: "6px"}}>Run Payroll</div>*/}
            {/*</div>*/}

            <div className={'listContainer'}>
                <div className={'horizontalFlex listTitle'}>
                    <div className={'flex1 listTitleColT'}>
                        <span className={'listTitleColText'}>id</span>
                    </div>
                    <div className={'flex1 listTitleColT'}>
                        <span className={'listTitleColText'}>period</span>
                    </div>
                    <div className={'flex1 listTitleColT'}>
                        <span className={'listTitleColText'}>total</span>
                    </div>
                    <div className={'flex1 listTitleColT'}>
                        <span className={'listTitleColText'}>payroll</span>
                    </div>
                    <div className={'flex1 listTitleColT'}>
                        <span className={'listTitleColText'}>deductions</span>
                    </div>
                    <div className={'flex1 listTitleColT'}>
                        <span className={'listTitleColText'}>contributions</span>
                    </div>
                </div>

                {/*{renderEmployees()}*/}

            </div>
        </div>
    )
}

export default RunPayroll;
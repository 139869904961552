import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {nameSelector} from "../../functions/navigationHelpersFunctions";
import {ExclamationCircleIcon} from "../../resources/icons";
import Tooltip from "./Tooltip";

const PageTitle = () => {
    const dispatch = useDispatch();
    const {activePageTitle, activeSideNavigation, activeTopNavigation} = useSelector((store) => store.navigation);

    return (
    <div className={'daughterLoaderTitle horizontalFlex'}>
        <span className={'daughterLoaderTitleBase inlineBlock'}>{nameSelector(activeTopNavigation)}</span>
        <div className={'inlineBlock titleSlash'}> / </div>
        <span className={'daughterLoaderTitleSub inlineBlock'}>{nameSelector(activeSideNavigation)}</span>
        <span className={'flex1'}/>
        <Tooltip content={'description will be sourced from here describing the elements and what not so that it' +
            'makes sense to the user'} direction="bottom" delay="0">
            <ExclamationCircleIcon class={'pointer'}/>
        </Tooltip>
    </div>)
}

export default PageTitle;
import React from 'react';
import {defaultSideNavSelector, iconSelector} from "../../functions/navigationHelpersFunctions";
import {useDispatch} from "react-redux";
import {setSideNavigation, setTopNavigation} from "../../redux/features/NavigationSlice";

const TopNavigationButton = (props) => {
    const dispatch = useDispatch();
    const setActiveTabsAndTitle = (id) => {
        // set Active Page Title

        // set Active Top Navigation
        dispatch(setTopNavigation(id));

        // set Active Side Navigation
        dispatch(setSideNavigation(defaultSideNavSelector(id)));
    }

    return (
        <div className={`topNavBarNavigationButton ${props.isActive ? 'active':''}`} onClick={()=>{setActiveTabsAndTitle(props.id)}}>
            {iconSelector(props.icon, props.isActive, "topNavBarNavigationButtonIcon")}
            <div className={'topNavBarNavigationButtonTitle'}>
                {props.title}
            </div>
        </div>
    );

}

export default TopNavigationButton;

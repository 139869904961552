const RowTile = (props) => {
    return (
        <div className={'listTile listTile-component horizontalFlex'}>
            <div className={`listTile-component-dot ${props.required ? 'required': ''}`}></div>
            <div className={'verticalFlex'}>
                <div className={'listTile-component-title'}>{props.label}</div>
                <div className={'listTile-component-notes'}>
                    {props.defaultValue && <span style={{borderRadius: '3px', display: 'inline-block', backgroundColor: '#f1f1f1', padding:'1px 4px', margin:'2px 4px 0px 0px'}}>
                            {props.defaultValue}</span>}
                    {props.description || ""}</div>
            </div>
            <div className={'flex1'}/>
            <div className={'horizontalFlex'}>
                <div className={'listTile-component-buttons pointer'}>
                    <div className={'button'} onClick={props.onClickEdit}>
                        Edit
                    </div>
                    <div className={'splitter-xsd'}/>
                    <div className={'button'}>Delete</div>
                </div>
            </div>
        </div>
    )
}

export default RowTile;
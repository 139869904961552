import React from 'react';
import logo from "../../resources/assets/fiigersLogo.png";
import TopNavigationButton from "./TopNavigationButton";
import {
    DEDUCTIONS_ICON,
    HOME_ICON,
    OFFICE_ICON,
    PAYROLL_ICON,
    PEOPLE_ICON, TOP_NAV_EMPLOYEES,
    TOP_NAV_HOME, TOP_NAV_MY_ORG,
    TOP_NAV_PAYROLL, TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS
} from "../../functions/constants";
import {useDispatch, useSelector} from "react-redux";
import {DownArrow} from "../../resources/icons";
import {setModalContent, toggleModal} from "../../redux/features/LocalStateSlice";
import {setEmployeesSnapshot} from "../../redux/features/SnapshotSlice";
import {users} from "../../resources/dirtyContext/dirtyUsers";
import users_ from '../../resources/dirtyContext/users.json';
import {Link} from "react-router-dom";

const TopNavBar = () => {
    const dispatch = useDispatch();
    const {activeTopNavigation} = useSelector((store)=>store.navigation);
    const {currentModalSpec} = useSelector((store) => store.localState);
    const getIsActive =(val) => {
        return val === activeTopNavigation;
    }
    const data = {
        fName: "Laura",
        lName: "Kemiteto",
        companyName: "Claire Button Finance Limited"
    }
    const onClickProfile = () => {
        // if dirty context? ask to clear
        let dirtyContext;
        if (currentModalSpec && currentModalSpec.message && currentModalSpec.message === "Load and use sample data?") {
            dirtyContext = {
                kind: "WARNING",
                message: "Clear sample data?",
                onSubmit: () => {
                    dispatch(setEmployeesSnapshot(null));
                }
            }
        } else {
            dirtyContext = {
                kind: "WARNING",
                message: "Load and use sample data?",
                onSubmit: () => {
                    dispatch(setEmployeesSnapshot(users));
                }
            }
        }

        // else ask to populate dirty context

        dispatch(toggleModal(false));
        dispatch(setModalContent(dirtyContext));

    }

    return (
        <div className={'topNavBar'}>
            <div className={'topNavBarTopper'}>

                <img src={logo} alt='fiigers_logo' className='navigationBarLogo'></img>
                <div style={{height: '10px', width: '10px', borderRadius: '5px', backgroundColor:'#fff', margin: '0px 3px'}}></div>
                <div className={'payrollLogo'}>Payroll</div>

                <div className={'flex1'}></div>
                <div className={'companySelector'}>
                    <div className={'companySelector-text'}>{data.companyName}</div>
                <DownArrow/>
                </div>
                <div className={'companyLogoContainer'} onClick={onClickProfile}>{`${data.fName[0]}${data.lName[0]}`}</div>

            </div>
            <div className={'topNavBarNavigationContainer'}>
                <Link to={'/in/home/v/dashboard'}>
                    <TopNavigationButton title={"Home"} icon={HOME_ICON} isActive={getIsActive(TOP_NAV_HOME)} id={TOP_NAV_HOME}/>
                </Link>
                <Link to={'/in/payroll/v/run-payroll'}>
                    <TopNavigationButton title={"Payroll"} icon={PAYROLL_ICON} isActive={getIsActive(TOP_NAV_PAYROLL)} id={TOP_NAV_PAYROLL}/>
                </Link>

                <Link to={'/in/employees/v/view'}>
                    <TopNavigationButton title={"Employees"} icon={PEOPLE_ICON} isActive={getIsActive(TOP_NAV_EMPLOYEES)} id={TOP_NAV_EMPLOYEES}/>
                </Link>
                <Link to={'/in/my-org/v/view'}>
                    <TopNavigationButton title={"My org"} icon={OFFICE_ICON} isActive={getIsActive(TOP_NAV_MY_ORG)} id={TOP_NAV_MY_ORG}/>
                </Link>
                <Link to={'/in/deductions-&-contributions/v/manage-deductions'}>
                    <TopNavigationButton title={"Deductions & Contributions"} icon={DEDUCTIONS_ICON} isActive={getIsActive(TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS)} id={TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS}/>
                </Link>
                {/*<TopNavigationButton title={"Home"} icon={HOME_ICON} isActive={getIsActive(TOP_NAV_HOME)} id={TOP_NAV_HOME}/>*/}
                {/*<TopNavigationButton title={"Payroll"} icon={PAYROLL_ICON} isActive={getIsActive(TOP_NAV_PAYROLL)} id={TOP_NAV_PAYROLL}/>*/}
                {/*<TopNavigationButton title={"Employees"} icon={PEOPLE_ICON} isActive={getIsActive(TOP_NAV_EMPLOYEES)} id={TOP_NAV_EMPLOYEES}/>*/}
                {/*<TopNavigationButton title={"My org"} icon={OFFICE_ICON} isActive={getIsActive(TOP_NAV_MY_ORG)} id={TOP_NAV_MY_ORG}/>*/}
                {/*<TopNavigationButton title={"Deductions & Contributions"} icon={DEDUCTIONS_ICON} isActive={getIsActive(TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS)} id={TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS}/>*/}


            </div>
        </div>
    );

};

export default TopNavBar;
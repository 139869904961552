import React from 'react';
import { Helmet } from 'react-helmet';
import '../index.css';

import TopNavBar from "./common/TopNavBar";
import SideNavBar from "./common/SideNavBar";
import {useDispatch, useSelector} from "react-redux";
import {employeeTabs, payRollTabs} from "../functions/tabBuilder";
import {bodySelector, defaultSideNavSelector, tabSelector} from "../functions/navigationHelpersFunctions";
import PageTitle from "./common/PageTitle";
import Modal from "./viewBlockers/Modal";
import MotherBlocker from "./viewBlockers/MotherBlocker";
import {setSideNavigation, setTopNavigation} from "../redux/features/NavigationSlice";
import {SIDE_NAV_PAYROLL_RUN_PAYROLL} from "../functions/constants";

const Body = (props) => {

    const dispatch = useDispatch();
    const {activePageTitle, activeSideNavigation, activeTopNavigation} = useSelector((store) => store.navigation);
    const {showModal, showMotherLoader, currentModalSpec, currentMotherLoaderSpec}
        = useSelector((store) => store.localState)

    const getPageTitle = () => {
        let pageTitle  = activePageTitle;
        return pageTitle;
    }
    const getSideNavTabs = () => {
        let activeSideNav = activeTopNavigation;
        return tabSelector(activeSideNav);
    }

    const getCurrentPage = () => {
        let selection = activeSideNavigation;
        return bodySelector(selection);
    }
    if (typeof props !== 'undefined') {
        if ("id" in props) {
            dispatch(setTopNavigation(props.id));
            // set Active Side Navigation
            if ("side" in props) {
                dispatch(setSideNavigation(props.side));
            } else {
                dispatch(setSideNavigation(defaultSideNavSelector(props.id)));
            }

     }

    }
    return (

        <div id={'root'}>
            <Helmet>
                <meta charSet='utf-8'/>
                <title> {getPageTitle()} | Fiigers Payroll</title>
            </Helmet>

            <TopNavBar/>

            <div className={'daughterLoader'}>
                <SideNavBar tabs={getSideNavTabs()}/>

                <div className={'daughterLoaderBody'}>
                    <PageTitle/>
                    <div className={'daughterLoaderContent'}>
                        {getCurrentPage()}
                    </div>
                </div>
            </div>

            <div className={'sideBlocker'}>
                <div className={'sideBlockerBody'}>
                    <div className={'sb-content'}>
                        <div> Close </div>

                    </div>
                </div>
            </div>

            {showMotherLoader && <MotherBlocker/>}

            {showModal && <Modal spec={currentModalSpec}/>}
        </div>
    );
};

export default Body;

import {useDispatch, useSelector} from "react-redux";
import {toggleModal} from "../../redux/features/LocalStateSlice";

const Modal = (props) => {
    const dispatch = useDispatch();
    const {currentModalSpec} = useSelector((store) => store.localState);
    // get information from state management i.e message, kind of header
    const headerKind = ""; // Error! Caution ! Success (New user created?, Org submit)
    const message = ""; // Are you sure you want to delete this record?
    const showFooter = true; // if footer present, wait for action. Otherwise, disappear after a minute or two
    const onCancelClick = ""; // do something from a switch of functions or maybe we can pass functions
    const onOkClick = ""; // same as above, maybe pass function and see if we can get something working
    const headerKindString = () =>{
        switch (currentModalSpec.kind) {
            case "ERROR":
                return "🚨 Error!"
            case "WARNING":
                return "⚠️ Warning!"
            case "SUCCESS":
                return "👍 Success!"
            case "PROMPT":
                return "❓PROMPT❓"
            default:
                return "❓PROMPT❓"
        }
    }
    const closeModal = () => {
        dispatch(toggleModal(true));
    }
    const submitAndClose =() => {
        currentModalSpec.onSubmit();
        dispatch(toggleModal(true));
    }
    return (
        <div className={'modalRoot'}>
            <div className={'modalBackground'}></div>
            <div className={'modalMother'}>
                <div className={'modalDaughter'}>
                    <div className={'modalHeader'}>{headerKindString()}</div>
                    {/*Error! Caution ! Success*/}
                    <div className={'modalBody'}>{currentModalSpec.message}</div>
                    <div className={'modalFooter'}>
                        <div className={'flex1 modalButton'} onClick={closeModal}>
                            Cancel
                        </div>
                        <div className={'flex1 modalButton'} onClick={submitAndClose}>
                            OK
                        </div>
                    </div>
                </div>
                <div className={'flex1'}/>

            </div>

        </div>
    );
}
export default Modal;
// const ManageDeductions = () => {
//     return (
//         <div>
//             Name
//             Value
//             Type (FIXED, VARIABLE, SCALE)
//             Components to consider (pay components)
//             Calculation path for scaled ( this path will have the excel function)
//             {/*associated account number can be added later e.g. Momo, Bank account details*/}
//
//         </div>
//     );
// }
//
// export default ManageDeductions;
import Checkbox from "../common/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {onChangeAddTagDescriptionInput,
    onChangeAddTagInput, onChangeAddTagRequiredInput,
    onChangeIsEditingTag, onChangeSelectedTag
} from "../../redux/features/MyOrgSlice";
// import {
//     saveChangesToTagFunction,
//     submitAddTagFormFunction
// } from "./functions";
import RowTile from "../common/RowTile";
import TitledSelect from "../common/TitledSelect";
import TitledInput from "../common/TitledInput";
import {deductionTypeOptions, periodTypeOptions} from "../../data/Options";
import CreateDeductionsModal from "./CreateDeductionsModal";
import {toggleCreateDeductionModal} from "../../redux/features/DedsAndContribsSlice";

const ManageDeductions = () => {

    const dispatch = useDispatch();

    const {
        showCreateDeductionModal
    } = useSelector((store) => store.deductionsAndContributions);
    const {deductionsSnapshot, } = useSelector((store)=> store.snapshot);

    const updateSelectedTag = (item) => {
        dispatch(onChangeIsEditingTag(true));
        dispatch(onChangeSelectedTag(item));
    }



    const toggleModalPrompt = (input) => {
        dispatch(toggleCreateDeductionModal(!input));
    }

    const renderRowTiles = () => {
        let data_ = deductionsSnapshot;
        return data_.map( item =>
            <RowTile required={item.required} label={item.name} defaultValue={item.amount}
                     description={`${item.type.label}. Per: ${item.period.label}. Max per year: ${item.maxPerYear}`} onClickEdit={()=>{updateSelectedTag(item)}}/>
        );
    }
    return (
        <div>
            <div className={'horizontalFlex'}>
                <div className={'createDeductionButton'} onClick={()=>{toggleModalPrompt(true)}}>
                    + Create Deduction
                </div>
                <div className={'flex1'}/>
            </div>
            <div className={'bdx-100'}></div>
            {renderRowTiles()}

            {/*NIN NSSF ID TIN ORG_ID*/}
            <div className={'listTile'}/>
            <div className={'listTile'}/>
            <div className={'listTile'}/>

            {showCreateDeductionModal && <CreateDeductionsModal/>
                // <div className={'createDeductionsBlocker'}>
                //     <div className={'createDeductionsBackground'}/>
                //     <div className={'createDeductionsModal horizontalFlex'}>
                //         <div className={'createModalForm flex1 verticalFlex'} style={{padding: '12px'}}>
                //             <div className={'createDeductionTitle'}>Create Deduction</div>
                //
                //             <TitledInput title={'Name'} placeholder={'Name'}/>
                //             <TitledSelect title={'Type'} options={deductionTypeOptions}/>
                //             <TitledInput title={'Amount'} placeholder={'Amount'}/>
                //             <TitledSelect title={'Period'} options={periodTypeOptions}/>
                //             <TitledInput title={'Max per year'} placeholder={'Max per year: defaults to unlimited'}/>
                //
                //
                //
                //             <div className={'flex1'}></div>
                //             <div className={'horizontalFlex manageComponent-required-container'} style={{margin: '10px 0px'}}>
                //                 <Checkbox checked={true} title={'Required?'}
                //                           value={addTagRequiredInput}
                //                           onClick={updateAddTagRequired}/>
                //             </div>
                //             <div className={'horizontalSplitter-20px'}/>
                //             <div className={`formSubmit-green ${isEditingTag ? 'editing':''}`}
                //                  onClick={isEditingTag ? saveChangesToTag : submitForm}>
                //                 {isEditingTag ? "Save" : "+ Create"}
                //             </div>
                //
                //
                //
                //         </div>
                //         <div className={'createDeductionsComponentsHolder'} style={{padding: '12px'}}>
                //             <div className={'createDeductionTitle'}>Components</div>
                //         </div>
                //
                //     </div>
                // </div>

            }
        </div>
    )
}
export default ManageDeductions;
import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Jan',
        payroll: 0,
        contributions: 0,
        amt: 0,
    },
    {
        name: 'Feb',
        payroll: 3000,
        contributions: 1398,
        amt: 2210,
    },
    {
        name: 'Mar',
        payroll: 2000,
        contributions: 9800,
        amt: 2290,
    },
    {
        name: 'Apr',
        payroll: 2780,
        contributions: 3908,
        amt: 2000,
    },
    {
        name: 'May',
        payroll: 1890,
        contributions: 4800,
        amt: 2181,
    },
    {
        name: 'Jun',
        payroll: 2390,
        contributions: 3800,
        amt: 2500,
    },
    {
        name: 'Jul',
        payroll: 3490,
        contributions: 4300,
        amt: 2100,
    },
    {
        name: 'Aug',
        payroll: 3490,
        contributions: 4300,
        amt: 2100,
    },
    {
        name: 'Sep',
        payroll: 3490,
        contributions: 4300,
        amt: 2100,
    },
    {
        name: 'Oct',
        payroll: 3490,
        contributions: 4300,
        amt: 2100,
    },
    {
        name: 'Nov',
        payroll: 3490,
        contributions: 4300,
        amt: 2100,
    },
    {
        name: 'Dec',
        payroll: 3490,
        contributions: 4300,
        amt: 2100,
    },
];

export default class StackedBarChart extends PureComponent {
    static demoUrl = 'https://codesandbox.io/s/stacked-bar-chart-s47i2';

    render() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/*<Bar dataKey="payroll" stackId="a" fill="#4D0ABA" />*/}
                    <Bar dataKey="payroll" stackId="a" fill="#164CB2" />
                    <Bar dataKey="contributions" stackId="a" fill="#ccc" />

                    {/*<Bar dataKey="contributions" stackId="a" fill="#EBEBEB" />*/}
                    {/*<Bar dataKey="payroll" stackId="a" fill="#164CB2" />*/}
                    {/*<Bar dataKey="contributions" stackId="a" fill="#ECB32E" />*/}
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

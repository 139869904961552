import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import StackedBarChart from "../charts/StackedBarChart";
const Dashboard =()=>{
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    return (
        <div className={'dashboard'}>


            <div className="container">

                <div className="row">
                    <div className="col content">


                        <div style={{ width: "100%", border: "1px solid #CED4E0", padding:"20px",
                            fontSize: "22px", fontWeight: "bold", marginBottom: "20px", borderRadius:'6px'
                        }}> <span style={{color: "#ECB32E"}}>4</span> days until payday</div>


                        <div className={'section1'}>
                            <div className={'col1 col'}>
                                <StackedBarChart/>
                            </div>
                        </div>
                    </div>

                    <div className="col sidebar">

                        <div style={{ width: "100%", border: "1px solid #CED4E0", padding:"20px",
                            fontSize: "20px", fontWeight: "bold", marginBottom: "20px", height: "250px", borderRadius:'6px'
                        }}>
                            <div className={"dsh-section-title"}>Top 5 Spend</div>

                            <div className={"dsh-top-spend"}>
                                <div className={"item"}>Salaries</div>
                                <div className={"count"}>650,000,000</div>
                            </div>
                            <div className={"dsh-top-spend"}>
                                <div className={"item"}>Housing allowance</div>
                                <div className={"count"}>510,000,000</div>
                            </div>
                            {/*<div className={"dsh-top-spend"}>*/}
                            {/*    <div className={"item"}>NSSF Contributions</div>*/}
                            {/*    <div className={"count"}>500,000,000</div>*/}
                            {/*</div>*/}
                            {/*<div className={"dsh-top-spend"}>*/}
                            {/*    <div className={"item"}>Fuel Allowance</div>*/}
                            {/*    <div className={"count"}>100,000,000</div>*/}
                            {/*</div>*/}
                            {/*<div className={"dsh-top-spend"}>*/}
                            {/*    <div className={"item"}>Salaried</div>*/}
                            {/*    <div className={"count"}>0</div>*/}
                            {/*</div>*/}
                        </div>

                        <div className="col" style={{border: "1px solid #CED4E0", height: "135px", padding: "10px",
                            background:"#f9f9f9", borderRadius:'6px'}}>
                            <div style={{color: "#ECB32E"}}>Quick actions:</div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="container">

                <div className="row">

                    <div className="col sidebar">

                        <div style={{ width: "100%", border: "1px solid #CED4E0", padding:"20px",
                            fontSize: "20px", fontWeight: "bold", marginBottom: "20px", height: "405px", borderRadius:'6px'
                        }}>
                            <div className={"dsh-section-title"}>Employee composition</div>

                            <div className={"dsh-emp-comp"}>
                                <div className={"item"}>Contractors</div>
                                <div className={"count"}>0</div>
                            </div>

                            <div className={"dsh-emp-comp"}>
                                <div className={"item"}>Employees</div>
                                <div className={"count"}>0</div>
                            </div>

                            <div className={"dsh-emp-comp"}>
                                <div className={"item"}>Hourly</div>
                                <div className={"count"}>0</div>
                            </div>

                            <div className={"dsh-emp-comp"}>
                                <div className={"item"}>Salaried</div>
                                <div className={"count"}>0</div>
                            </div>
                        </div>
                    </div>


                    <div className="col content">
                        <div style={{ width: "100%", border: "1px solid #CED4E0", padding:"20px",
                            fontSize: "20px", fontWeight: "bold", marginBottom: "20px", height: "405px", borderRadius:'6px'
                        }}>
                            <div className={"dsh-section-title"}>Organisation Summary</div>

                            <div className={"dsh-section-title"}>Most recent Payroll</div>

                                <div className={"dsh-section-title"}>Drafts Payroll</div>

                        </div>
                    </div>
                </div>
            </div>




            {/*<div className={'section1'}>*/}
            {/*    <div className={'col2'}>*/}
            {/*        <div className={'col2_1'}>*/}
            {/*            Contractors*/}
            {/*            <div className={'flex1'}/>*/}
            {/*            <div className={'horizontalFlex'}>*/}
            {/*                <div className={'flex1'}/>*/}
            {/*                <span style={{fontSize:'50px'}}>5</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={'col2_2'}>*/}
            {/*            <div className={'row1'}>*/}
            {/*                Employees*/}
            {/*                <div className={'flex1'}/>*/}
            {/*                <div className={'horizontalFlex'}>*/}
            {/*                    <div className={'flex1'}/>*/}
            {/*                    <span style={{fontSize:'30px'}}>30</span>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className={'row2'}>*/}
            {/*                <div className={'r2c1'}>*/}
            {/*                    Hourly*/}
            {/*                    <div className={'flex1'}/>*/}
            {/*                    <div className={'horizontalFlex'}>*/}
            {/*                        <div className={'flex1'}/>*/}
            {/*                        10*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className={'r2c2'}>*/}
            {/*                    Salaried*/}
            {/*                    <div className={'flex1'}/>*/}
            {/*                    <div className={'horizontalFlex'}>*/}
            {/*                        <div className={'flex1'}/>*/}
            {/*                        20*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}





            {/*<div className={'section1'}>*/}
            {/*    <div className={'col1'}>*/}
            {/*        <StackedBarChart/>*/}
            {/*    </div>*/}
            {/*</div>*/}




        </div>
    )
}

export default Dashboard;
// use for general state management that does not need a reducer of its own

import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showMotherLoader: false,
    showOnboardHelper: false,
    showModal: false,

    currentMotherLoaderSpec: null,
    currentModalSpec: null,

    selectedEmployee: null
// {
//         kind: "WARNING",
//         message: "load and use sample data?",
//         onSubmit: () => {alert("clicked")}
//     }
}

const localStateSlice = createSlice({
    name: 'localStateSlice',
    initialState,
    reducers: {
        toggleMotherLoader: (state, action) => {
            state.showMotherLoader = !action.payload;
        },
        toggleModal: (state, action) => {
            state.showModal = !action.payload;
        },
        toggleOnboardHelper: (state, action) => {
            state.showOnboardHelper = !action.payload;
        },
        setMotherLoaderContent: (state, action) => {
            state.currentMotherLoaderSpec = action.payload;
        },
        setModalContent: (state, action) => {
            state.currentModalSpec = action.payload;
        },
        setSelectedEmployee: (state, action) => {
            state.selectedEmployee = action.payload;
        }
    }
});

export const {
    toggleModal, toggleMotherLoader, toggleOnboardHelper, setMotherLoaderContent, setModalContent, setSelectedEmployee
} = localStateSlice.actions;
export default localStateSlice.reducer;

import FlexBlocker from "../common/FlexBlocker";
import {useRef, useState} from "react";
import _ from "lodash";
import {users} from "../../resources/dirtyContext/dirtyUsers";
import {useDispatch, useSelector} from "react-redux";
import {
    setMotherLoaderContent,
    setSelectedEmployee,
    toggleModal,
    toggleMotherLoader
} from "../../redux/features/LocalStateSlice";
import { AnalyticsProvider, useAnalytics } from 'use-analytics';
import ViewEmployeesCustomizer from "./ViewEmployeesCustomizer";
import {BarsIcon} from "../../resources/icons";
import MoreIcon from "../common/MoreIcon";
import {toggleShowViewEmployeesCustomizer} from "../../redux/features/EmployeeSlice";
import employeeComponents from '../../resources/employeeComponents.json';
import { evaluate } from "mathjs";


const ViewEmployees = () => {
    const dispatch = useDispatch();
    const { track, page, identify } = useAnalytics();
    const {showViewEmployeesCustomizer} = useSelector((store) => store.employees);
    const { preferences, employeesSnapshot } = useSelector((store) => store.snapshot);


    // //create local context for column fields
    // check if preferences is populated, otherwise use local defaults preferencesDotOrderedSelectedCols
    let prefOrDefaults = preferences && preferences.employeeViewOrder.length > 0 ? preferences.employeeViewOrder: employeeComponents.default;
    const [searchString, setSearchString] = useState("");

    const getValueFromPath = (obj, index) => {
        // const path = localContextOrder[index].path;
        let operationObj = prefOrDefaults[index].operation;
        if (operationObj) {
            if (operationObj.type == "TEXT_JOIN") {
                // loop through arr and join text
                if (operationObj.fromArrayObject) { // if object is in array, select the primary
                    let arr = _.get(obj, operationObj.fromArrayObject, "");
                    obj = _.find(arr,(item) => { // extract primary from object
                        return item.primary;
                    });
                }
                return _.reduce(operationObj.value, (concat, value) => {
                    return concat + " " + _.get(obj, value, "");
                }, "");
            } else if (operationObj.type == "EVAL") {
                let toEvaluate = _.reduce(operationObj.value, (concat, value) => {
                    if (value.charAt(0) == "%") {
                        return concat + " " + value.charAt(1);
                    }
                    return concat + " " + _.get(obj, value, "0");
                }, "");
                return evaluate(toEvaluate);
            }
            return "";
        }
        if (prefOrDefaults[index].fromArrayObject) { // if object is in array, select the primary
            let arr = _.get(obj, prefOrDefaults[index].fromArrayObject, "");
            obj = _.find(arr,(item) => { // extract primary from object
                return item.primary && item.primary || item;
            });
        }
        let path = prefOrDefaults[index].path;
        return _.get(obj, path, "");
    }

    const selectEmployee = (id, employee) => {
        // dispatch id to view so that search will be filtered with current item only
        dispatch(setSelectedEmployee(employee));
        // show modal
        let motherLoaderContext = {
            title: "Employee Details"
        }
        dispatch(setMotherLoaderContent(motherLoaderContext))
        dispatch(toggleMotherLoader(false));

    }
    const showCustomizer = () => {
        dispatch(toggleShowViewEmployeesCustomizer(true))
    }


    const renderEmployees =()=> {
        // if searching, use search
        let searchString_ = searchString.toLowerCase();
        let searchingData = searchString_ && _.filter(employeesSnapshot, (employee) => {
            // _.values(employee).some(elems => elems.includes(""))
            // search by fname, lname, name, id type, employment type, pay type
            // eventually pay components, labels, deductions
            return employee.generalInfo.fName.toLowerCase().includes(searchString) ||
                employee.generalInfo.lName.toLowerCase().includes(searchString) ||
                (employee.generalInfo.fName.toLowerCase() + " " + employee.generalInfo.lName.toLowerCase()).includes(searchString) ||
                (employee.generalInfo.lName.toLowerCase() + " " + employee.generalInfo.fName.toLowerCase()).includes(searchString) ||
                employee.employmentInfo.employmentType.toLowerCase().includes(searchString) ||
                employee.employmentInfo.payType.toLowerCase().includes(searchString);

        })
        // otherwise, use snapshot

        let data = searchingData && searchString_ ? searchingData : employeesSnapshot;
        return data && data.map(item => {
            let size = prefOrDefaults.length;
            return <div className={'viewEmployeeListTile horizontalFlex listRow'} onClick={()=>{selectEmployee(item.id, item)}}>
                {
                    _.times(size, (index) => {
                    return <div className={'flex1 listColContainer'}>
                        <div className={'listColText'}>{getValueFromPath(item, index)}</div>
                    </div>
                })
                }
            </div>
        });
    }

    const renderColumnTitles =()=> {
        return prefOrDefaults.map(item => {
            return <div className={'flex1 listColContainer'}>
                <div className={'viewEmployeeListTitleTile listColText listColText '}>{item.label}</div>
            </div>
        });
    }



    return (
        <div>
            <div className={'horizontalFlex'} style={{alignItems:'center'}}>
                <div className={'searchBar'}>
                    <input className={'input'}
                           placeholder={'Search Employees'}
                           value={searchString}
                           onChange={(event)=>{setSearchString(event.target.value)}}
                    />
                </div>
                <FlexBlocker/>
                <MoreIcon onClick={showCustomizer}/>
            </div>

            <div className={'listContainer'}>
                <div className={'horizontalFlex listTitle'}>
                    {renderColumnTitles()}
                </div>

                {renderEmployees()}

            </div>
            {showViewEmployeesCustomizer && <ViewEmployeesCustomizer/>}

        </div>
    )
}

export default  ViewEmployees;
import React from 'react';
import {
    HomeIcon,
    OfficeIcon,
    PeopleIcon,
    PayrollIcon,
    DeductionsIcon,
    SettingsIcon,
    CursorIcon,
    UserGroupIcon,
    PresentationChartIcon,
    DashBoardIcon,
    UserPlusIcon,
    PieChartIcon,
    TagIcon,
    Square3Stack,
    Square3StackSolid, ListIcon
} from "../resources/icons";
import {
    HOME_ICON,
    OFFICE_ICON,
    PEOPLE_ICON,
    PAYROLL_ICON,
    DEDUCTIONS_ICON,
    HISTORY_ICON,
    SETTINGS_ICON,
    RUN_PAYROLL_ICON,
    PAY_CONTRACTORS_ICON,
    TOP_NAV_PAYROLL,
    TOP_NAV_EMPLOYEES,
    STRING_PAYROLL,
    TOP_NAV_HOME,
    STRING_HOME,
    STRING_EMPLOYEES,
    TOP_NAV_MY_ORG,
    STRING_MY_ORG,
    TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS,
    STRING_DEDUCTIONS_AND_CONTRIBUTIONS,
    SIDE_NAV_PAYROLL_RUN_PAYROLL,
    STRING_RUN_PAYROLL,
    SIDE_NAV_PAYROLL_PAY_CONTRACTORS,
    STRING_PAY_CONTRACTORS,
    SIDE_NAV_PAYROLL_HISTORY,
    STRING_HISTORY,
    SIDE_NAV_PAYROLL_SETTINGS,
    STRING_SETTINGS,
    DASHBOARD_ICON,
    USER_PLUS_ICON,
    SIDE_NAV_HOME_DASHBOARD,
    SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES,
    SIDE_NAV_MY_ORG_VIEW_ORG,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_CONTRIBUTIONS,
    STRING_VIEW_EMPLOYEES,
    SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES,
    STRING_MANAGE_DEDUCTIONS,
    STRING_ADD_EMPLOYEES,
    SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES,
    STRING_MANAGE_EMPLOYEES,
    STRING_VIEW_ORG,
    SIDE_NAV_MY_ORG_MANAGE_ORG,
    STRING_MANAGE_ORG,
    SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS,
    STRING_MANAGE_PAY_COMPONENTS,
    SIDE_NAV_MY_ORG_MANAGE_TAGS,
    STRING_MANAGE_TAGS,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_DEDUCTIONS,
    STRING_VIEW_DEDUCTIONS,
    STRING_VIEW_CONTRIBUTIONS,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS,
    STRING_MANAGE_CONTRIBUTIONS,
    STRING_DASHBOARD,
    PIE_CHART_ICON,
    TAG_ICON,
    SQUARE_3_STACK_ICON,
    SQUARE_3_STACK_ICON_SOLID, CONTRIBUTIONS_ICON
} from "./constants";
import {deductionsAndContributionsTabs, employeeTabs, homeTabs, myOrgTabs, payRollTabs} from "./tabBuilder";
import DefaultInactivePage from "../components/DefaultInactivePage";
import RunPayroll from "../components/payroll/RunPayroll";
import ViewEmployees from "../components/employees/ViewEmployees";
import ManageComponents from "../components/myOrg/ManageComponents";
import ManageTags from "../components/myOrg/ManageTags";
import Dashboard from "../components/home/Dashboard";
import ManageOrg from "../components/myOrg/ManageOrg";
import ViewOrg from "../components/myOrg/ViewOrg";
import AddEmployee from "../components/employees/AddEmployees";
import ManageEmployees from "../components/employees/ManageEmployees";
import AddEmployees from "../components/employees/AddEmployees";
import ManageDeductions from "../components/deductionsAndContributions/ManageDeductions";

export const iconSelector = (selection, isActive, className) => {
    switch (selection) {
        case HOME_ICON:
            return <HomeIcon isActive={isActive} className={className}/>;
        case OFFICE_ICON:
            return <OfficeIcon isActive={isActive} className={className}/>
        case PEOPLE_ICON:
            return <PeopleIcon isActive={isActive} className={className}/>;
        case PAYROLL_ICON:
            return <PayrollIcon isActive={isActive} className={className}/>;
        case DEDUCTIONS_ICON:
            return <DeductionsIcon isActive={isActive} className={className}/>
        case CONTRIBUTIONS_ICON:
            return <ListIcon isActive={isActive} className={className}/>
        case RUN_PAYROLL_ICON:
            return <CursorIcon isActive={isActive} className={className}/>;
        case PAY_CONTRACTORS_ICON:
            return <UserGroupIcon isActive={isActive} className={className}/>;
        case HISTORY_ICON:
            return <PresentationChartIcon isActive={isActive} className={className}/>;
        case SETTINGS_ICON:
            return <SettingsIcon isActive={isActive} className={className}/>
        case DASHBOARD_ICON:
            return <DashBoardIcon isActive={isActive} className={className}/>
        case USER_PLUS_ICON:
            return <UserPlusIcon isActive={isActive} className={className}/>
        case PIE_CHART_ICON:
            return <PieChartIcon isActive={isActive} className={className}/>
        case TAG_ICON:
            return <TagIcon isActive={isActive} className={className}/>
        case SQUARE_3_STACK_ICON:
            return <Square3Stack isActive={isActive} className={className}/>
        case SQUARE_3_STACK_ICON_SOLID:
            return <Square3StackSolid isActive={isActive} className={className}/>
        default:
            return;
    }
}

export const tabSelector = (selection) => {
    switch (selection) {
        case TOP_NAV_PAYROLL:
            return payRollTabs;
        case TOP_NAV_EMPLOYEES:
            return employeeTabs;
        case TOP_NAV_MY_ORG:
            return myOrgTabs;
        case TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS:
            return deductionsAndContributionsTabs;
        case TOP_NAV_HOME:
            return homeTabs
        default:
            return homeTabs;
    }
}

export const nameSelector = (selection) => {
    switch (selection) {
        case TOP_NAV_HOME:
            return STRING_HOME;
        case TOP_NAV_PAYROLL:
            return STRING_PAYROLL;
        case TOP_NAV_EMPLOYEES:
            return STRING_EMPLOYEES;
        case TOP_NAV_MY_ORG:
            return STRING_MY_ORG;
        case TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS:
            return STRING_DEDUCTIONS_AND_CONTRIBUTIONS;
        //    SIDE NAVIGATION TABS
        case SIDE_NAV_PAYROLL_RUN_PAYROLL:
            return STRING_RUN_PAYROLL;
        case SIDE_NAV_PAYROLL_PAY_CONTRACTORS:
            return STRING_PAY_CONTRACTORS;
        case SIDE_NAV_PAYROLL_HISTORY:
            return STRING_HISTORY;
        case SIDE_NAV_PAYROLL_SETTINGS:
            return STRING_SETTINGS

        case SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES:
            return STRING_VIEW_EMPLOYEES
        case SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES:
            return STRING_ADD_EMPLOYEES
        case SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES:
            return STRING_MANAGE_EMPLOYEES

        case SIDE_NAV_MY_ORG_VIEW_ORG:
            return STRING_VIEW_ORG
        case SIDE_NAV_MY_ORG_MANAGE_ORG:
            return STRING_MANAGE_ORG
        case SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS:
            return STRING_MANAGE_PAY_COMPONENTS
        case SIDE_NAV_MY_ORG_MANAGE_TAGS:
            return STRING_MANAGE_TAGS

        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_DEDUCTIONS:
            return STRING_VIEW_DEDUCTIONS
        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_CONTRIBUTIONS:
            return STRING_VIEW_CONTRIBUTIONS
        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS:
            return STRING_MANAGE_DEDUCTIONS
        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS:
            return STRING_MANAGE_CONTRIBUTIONS

        case SIDE_NAV_HOME_DASHBOARD:
            return STRING_DASHBOARD
    }
}

export const defaultSideNavSelector =(selection)=>{
    switch (selection) {
        case TOP_NAV_HOME:
            return SIDE_NAV_HOME_DASHBOARD
        case TOP_NAV_EMPLOYEES:
            return SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES
        case TOP_NAV_PAYROLL:
            return SIDE_NAV_PAYROLL_RUN_PAYROLL
        case TOP_NAV_MY_ORG:
            return SIDE_NAV_MY_ORG_VIEW_ORG
        case TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS:
            return SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS
    }
}

export const sideRouteSelector = (selection) => {
    switch (selection) {
        case SIDE_NAV_PAYROLL_RUN_PAYROLL:
            return "run-payroll";
        case SIDE_NAV_PAYROLL_PAY_CONTRACTORS:
            return STRING_PAY_CONTRACTORS;
        case SIDE_NAV_PAYROLL_HISTORY:
            return "history";
        case SIDE_NAV_PAYROLL_SETTINGS:
            return STRING_SETTINGS

        case SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES:
            return "view"
        case SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES:
            return "add"
        case SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES:
            return "manage"

        case SIDE_NAV_MY_ORG_VIEW_ORG:
            return "view"
        case SIDE_NAV_MY_ORG_MANAGE_ORG:
            return "manage-org"
        case SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS:
            return "manage-components"
        case SIDE_NAV_MY_ORG_MANAGE_TAGS:
            return "manage-tags"

        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_DEDUCTIONS:
            return "view-deductions"
        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_CONTRIBUTIONS:
            return "view-contributions"
        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS:
            return "manage-deductions"
        case SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS:
            return "manage-contributions"

        case SIDE_NAV_HOME_DASHBOARD:
            return "dashboard"
    }
}

export const bodySelector =(selection)=>{
    switch (selection) {
        case SIDE_NAV_PAYROLL_RUN_PAYROLL:
            return <RunPayroll/>
        case SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES:
            return <ViewEmployees/>
        case SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES:
            return <AddEmployee/>
        case SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES:
            return <ManageEmployees/>

        case SIDE_NAV_MY_ORG_MANAGE_TAGS:
            return <ManageTags/>
        case SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS:
            return <ManageComponents/>
        case SIDE_NAV_MY_ORG_MANAGE_ORG:
            return <ManageOrg/>
        case SIDE_NAV_MY_ORG_VIEW_ORG:
            return <ViewOrg/>

        case SIDE_NAV_HOME_DASHBOARD:
            return <Dashboard/>
        default:
            return <ManageDeductions/>
    }
}
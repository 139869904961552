import {CheckmarkIcon} from "../../resources/icons";

const Checkbox =(props)=>{
    let additionalTitleStyle = "";
    let additionalCheckboxSquareStyle = "";
    let additionalCheckMarkStyle = "";
    return (
        <div className={'horizontalFlex'}>
            <div className={'checkbox-square'} onClick={()=> {props.onClick(props.value)}}>
                {props.value && props.value == true &&
                    <CheckmarkIcon className={'checkbox-checkmark'}/>
                }
            </div>
            {props.title && <div className={'checkbox-title'}>
                {props.title}
            </div>}

        </div>
    )
}
export default Checkbox;
import TitledInput from "../common/TitledInput";
import Select from 'react-select'
import {countryOptionsLimited, employmentTypeOptions, genderOptions, payTypeOptions} from "../../data/Options";
import {useDispatch, useSelector} from "react-redux";
import {
    onChangeBirthdateInput,
    onChangeFirstNameInput,
    onChangeGenderInput,
    onChangeLastNameInput,
    onChangeAddress1Input,
    onChangeEmailAddressInput,
    onChangeAddress2Input,
    onChangeCityInput,
    onChangeStateInput,
    onChangeCountryInput,
    onChangePostalCodeInput,
    onChangeMobileInput,
    onChangePersonalEmailInput,
    onChangeTagInputs,
    onChangePayComponentInputs
} from "../../redux/features/EmployeeSlice";
import TitledSelect from "../common/TitledSelect";
import _ from "lodash";

const AddEmployees =()=>{
    const TAGS = "tags";
    const PAY_COMPONENTS = "payComponents";
    const DEDUCTIONS = "deductions";
    const CONTRIBUTIONS = "contributions";

    const dispatch = useDispatch();
    const {
        firstNameInput,
        lastNameInput,
        birthdateInput,
        genderInput,
        address1Input,
        address2Input,
        cityInput,
        stateInput,
        countryInput,
        postalCodeInput,
        mobileInput,
        personalEmailInput,
        tagInputs,
        payComponentInputs

    } = useSelector((store) => store.employees);
    const {tagSnapshot, componentSnapshot} = useSelector((store) => store.snapshot);


    const updateFirstNameInput = (event) => {dispatch(onChangeFirstNameInput(event.target.value))};
    const updateLastNameInput = (event) => {dispatch(onChangeLastNameInput(event.target.value))};
    const updateBirthdateInput = (event) => {dispatch(onChangeBirthdateInput(event.target.value))};
    const updateGenderInput = (value) => {dispatch(onChangeGenderInput(value))};
    const updateEmailAddressInput = (event) => {dispatch(onChangeEmailAddressInput(event.target.value))};
    const updateAddress1Input = (event) => {dispatch(onChangeAddress1Input(event.target.value))};
    const updateAddress2Input = (event) => {dispatch(onChangeAddress2Input(event.target.value))};
    const updateCityInput = (event) => {dispatch(onChangeCityInput(event.target.value))};
    const updateStateInput = (event) => {dispatch(onChangeStateInput(event.target.value))};
    const updateCountryInput = (value) => {dispatch(onChangeCountryInput(value))};
    const updatePostalCodeInput = (event) => {dispatch(onChangePostalCodeInput(event.target.value))};
    const updateMobileInput = (event) => {dispatch(onChangeMobileInput(event.target.value))};
    const updatePersonalEmailInput = (event) => {dispatch(onChangePersonalEmailInput(event.target.value))};
    const updateTagInputs = (event, id) => {
        dispatch(onChangeTagInputs({id: id, value: event.target.value}))};
    const updatePayComponentInputs = (event, id) => {
        dispatch(onChangePayComponentInputs({id: id, value: event.target.value}))};

    const getValueFromTagsList = id => {
        let obj = tagInputs[id];
        return obj && obj.value || ""
    }

    const getValueFromPayComponentsList = (id, defaultValue) => {
        let obj = payComponentInputs[id];
        return obj && obj.value || obj == null && defaultValue && defaultValue || "";
    }

    const renderTags = () => {
        if (tagSnapshot.length === 0) return <div>No tags set</div>;
        return tagSnapshot.map(item =>
            <div className={'horizontalFlex'}>
                <TitledInput title={item.tag} placeholder={item.description || item.tag} value={getValueFromTagsList(item.id)}
                             onChange={(event)=> {updateTagInputs(event, item.id)}} userDefined={true}/>
                <div className={'horizontalSplitter-10px'}></div>
                <div className={'flex1'}/>
            </div>
        )
    }

    const renderInputs = selection => {
        switch(selection) {
            case TAGS:
                if (tagSnapshot.length === 0) return <div>No tags set</div>;
                return tagSnapshot.map(item =>
                    <div className={'horizontalFlex'}>
                        <TitledInput title={item.tag} placeholder={item.description || item.tag} value={getValueFromTagsList(item.id)}
                                     onChange={(event)=> {updateTagInputs(event, item.id)}} userDefined={true}/>
                        <div className={'horizontalSplitter-10px'}></div>
                        <div className={'flex1'}/>
                    </div>
                )
            case PAY_COMPONENTS:
                if (componentSnapshot.length === 0) return <div>No components set</div>;
                return componentSnapshot.map(item =>
                    <div className={'horizontalFlex'}>
                        <TitledInput title={item.payComponent} placeholder={item.description || item.payComponent}
                                     value={getValueFromPayComponentsList(item.id, item.defaultValue)}
                                     onChange={(event)=> {updatePayComponentInputs(event, item.id)}} userDefined={true}/>
                        <div className={'horizontalSplitter-10px'}></div>
                        <div className={'flex1'}/>
                    </div>
                )
        }
    }



    return (
        <div className={'manageOrg-container'}>

            <div className={'form-section-title'}>General Info</div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'first name'} placeholder={'first name'} value={firstNameInput}
                             onChange={updateFirstNameInput}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'last name'} placeholder={'last name'} value={lastNameInput}
                             onChange={updateLastNameInput}/>
            </div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'birthdate'} placeholder={'birthdate'}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledSelect title={'gender'} options={genderOptions}
                              value={genderInput}
                              onChange={updateGenderInput}/>
            </div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'email address'} placeholder={'email address'}
                value={personalEmailInput} onChange={updateEmailAddressInput}
                />
                <div className={'horizontalSplitter-10px'}></div>
                <div className={'flex1'}/>
            </div>


            <div className={'form-section-title'}>Personal Info</div>
            <div className={'horizontalFlex'}>
                <TitledInput title={'address 1'} placeholder={'address 1'} value={address1Input} onChange={updateAddress1Input}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'address 2'} placeholder={'address 2 (optional)'} value={address2Input}
                             onChange={updateAddress2Input}/>
            </div>
            <div className={'horizontalFlex'}>
                <TitledInput title={'city'} placeholder={'city'} value={cityInput} onChange={updateCityInput}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'state'} placeholder={'state'} value={stateInput} onChange={updateStateInput}/>
            </div>
            <div className={'horizontalFlex'}>
                <TitledSelect title={'country'} options={countryOptionsLimited}
                              value={countryInput}
                              onChange={updateCountryInput}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'postal code'} placeholder={'postal code'} value={postalCodeInput}
                             onChange={updatePostalCodeInput}/>
            </div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'mobile'} placeholder={'mobile'} value={mobileInput} onChange={updateMobileInput}/>
                <div className={'horizontalSplitter-10px'}></div>
                <div className={'flex1'}/>
            </div>


            <div className={'form-section-title'}>Identification Info</div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'Type '} placeholder={'Drivers License'}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'number'} placeholder={'number'}/>
            </div>

            <div className={'form-section-title'}>Employment Info</div>

            <div className={'horizontalFlex'}>
                <TitledSelect title={'employment type'} options={employmentTypeOptions}
                              // value={countryInput}
                              onChange={()=>{}}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledSelect title={'pay type'} options={payTypeOptions}
                    // value={countryInput}
                              onChange={()=>{}}/>
            </div>

            <div className={'form-section-title'}>Payment Info</div>
            <div className={'horizontalFlex'}>
                <TitledInput title={'Payment Type '} placeholder={'Bank'}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'Account number'} placeholder={'Account number'}/>
            </div>

            <div className={'form-section-title'}>Tag Info</div>
            {renderInputs(TAGS)}

            <div className={'form-section-title'}>Pay Components</div>
            {renderInputs(PAY_COMPONENTS)}

            <div className={'form-section-title'}>Deductions & Contributions</div>
            <div>No deductions & contributions set</div>


            {/*Indentication*/}
            {/*Type of identification [drivers license, passport, national Id] etc*/}
            {/*Value*/}
            {/*Eventually upload docs*/}

            {/*Tags*/}
            {/*self created e.g: NIN, TIN, VAT number, location, company Id number etc*/}

            {/*// at some point, type of tag can be one of and this will create a drop down of options for person to select*/}



            {/*Personal Info*/}
            {/*Address*/}
            {/*Phone*/}
            {/*Mobile*/}
            {/*Personal email*/}
            {/*address*/}

            {/*// SEE in next iteration we can group deductions (taxes based on labels for quick add)*/}
            {/*// Also allow for imports (will need to create excel that they populate and upload csv)*/}
            {/*// Eventually employee docs which they will upload*/}

            {/*Pay Components*/}

            {/*Deductions & Contributions*/}


            {/*TIN*/}
            {/*NSSF ID*/}

            <div className={'horizontalFlex'}>
                <div className={'flex1'}/>
                <div className={'formSubmit-green'}>Submit</div>
            </div>

        </div>
    )
}

export default AddEmployees;
import React from "react";
import SideNavBarButton from "./SideNavBarButton";
import {
    HISTORY_ICON,
    PAY_CONTRACTORS_ICON,
    RUN_PAYROLL_ICON,
    SETTINGS_ICON, SIDE_NAV_PAYROLL_HISTORY, SIDE_NAV_PAYROLL_PAY_CONTRACTORS,
    SIDE_NAV_PAYROLL_RUN_PAYROLL, SIDE_NAV_PAYROLL_SETTINGS
} from "../../functions/constants";
import {useSelector} from "react-redux";

const SideNavBar = (props) => {
    const {activeSideNavigation} = useSelector((store)=>store.navigation);
    const getIsActive =(val) => {
        return val === activeSideNavigation;
    }

    const renderNavTabs = () => {
        let tabsToRender = props.tabs;
        return tabsToRender.map(item =>
            <SideNavBarButton title={item.title} icon={item.icon} isActive={getIsActive(item.name)} id={item.name}/>)
    }

    return (
        <div className={'sideNavBar'}>
            { renderNavTabs() }
        </div>
    );
}

export default SideNavBar;
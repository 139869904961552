import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from "./features/NavigationSlice";
import myOrgReducer from './features/MyOrgSlice';
import snapshotReducer from './features/SnapshotSlice';
import employeeReducer from './features/EmployeeSlice';
import localStateReducer from './features/LocalStateSlice';
import deductionsAndContributionsReducer from './features/DedsAndContribsSlice';
import payrollReducer from "./features/PayrollSlice";

export const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        myOrg: myOrgReducer,
        snapshot: snapshotReducer,
        employees: employeeReducer,
        localState: localStateReducer,
        deductionsAndContributions: deductionsAndContributionsReducer,
        payroll: payrollReducer
    }
});
export const GENERAL_INFO = "generalInfo";
export const PERSONAL_INFO = "personalInfo";
export const IDENTIFICATION_INFO = "identificationInfo";
export const EMPLOYMENT_INFO = "employmentInfo";
export const PAY_INFO = "payInfo";
export const OTHER_INFO = "otherInfo";

export const detailsViewSelector = (selection) => {
    switch(selection) {
        case GENERAL_INFO:
            return generalInfoTabValues;
        case PERSONAL_INFO:
            return <div/>
        case IDENTIFICATION_INFO:
            return <div/>
        case EMPLOYMENT_INFO:
            return <div/>
        case PAY_INFO:
            return <div/>
        case OTHER_INFO:
            return <div/>
    }

};


const generalInfoTabValues = [
    {
        path: "generalInfo.fName",
        label: "first name"
    },
    {
        path: "generalInfo.lName",
        label: "last name"
    },
    {
        path: "generalInfo.gender",
        label: "first name"
    },
    {
        path: "generalInfo.email",
        label: "first name"
    }
];

const personalInfoTabValues = [
    {
        path: "personalInfo.fName",
        label: "first name"
    },
    {
        path: "personalInfo.lName",
        label: "last name"
    },
    {
        path: "personalInfo.gender",
        label: "first name"
    },
    {
        path: "personalInfo.email",
        label: "first name"
    }
];
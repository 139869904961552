import React from 'react';
import {defaultSideNavSelector, iconSelector, sideRouteSelector} from "../../functions/navigationHelpersFunctions";
import {useDispatch, useSelector} from "react-redux";
import {setSideNavigation} from "../../redux/features/NavigationSlice";
import {Link, useLocation} from "react-router-dom";

const SideNavBarButton = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {} = useSelector((store)=>store.navigation);
    const setActiveSideNavigation =(id)=> {
        dispatch(setSideNavigation(id));
    }

    const getSideRoute = (id) => {
        // use route selector
        let sideNavPath = sideRouteSelector(id)
        let topNavPath = location.pathname.split("/v/");

        return topNavPath[0] + "/v/" + sideNavPath;
    }
    const onButtonClick = (id) => {
        setActiveSideNavigation(id);
    }

    return (
        <Link to={getSideRoute(props.id)}>
            <div className={`sideNavBarNavigationButton ${props.isActive ? 'active':''}`}
                 onClick={()=>{onButtonClick(props.id)}}>
                { iconSelector(props.icon, props.isActive, "sideNavBarNavigationButtonIcon") }
                <div className={'sideNavBarNavigationButtonTitle'}>
                    {props.title}
                </div>
            </div>
        </Link>
    );
}

export default SideNavBarButton;

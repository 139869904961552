import {createSlice} from "@reduxjs/toolkit";

// This is the number of different views/tabs we have when running payroll.
// keep this count up to date as there is counter based logic
// we also use 1-index as oposed to 0-index
const TAB_COUNT = 5;
const initialState = {
    activeTab: 1,
    data: null, // use a copy of data as we may not want to save changes. reload from original snapshot when needed,
    activePreparation: false
}

const payrollSlice = createSlice({
    name: "payroll",
    initialState,
    reducers: {
        put_data: (state, action) => {
            state.data = action.payload;
        },
        update_data: (state, action) => {
            let currState = state;
            // get path to update then update with value to update
            state.data = currState;
        },
        next_tab: (state, action) => {
            // check  if tab is at max
            if (state.activeTab && state.activeTab >= TAB_COUNT) {
                // go back to init for now until we decide how to download? Prompt?
                state.activeTab = 1;
            } else {
                state.activeTab += 1;
            }
        },
        prev_tab: (state, action) => {
            // check if tab is at min = 1
            if (state.activeTab && state.activeTab <= 1) {
                state.activeTab = TAB_COUNT;
            } else {
                state.activeTab -= 1;
            }
        },
        cancel_current_process: (state, action) => {
            // reset everything including the data ...
        }
    }
});

export const { next_tab, prev_tab } = payrollSlice.actions;
export default payrollSlice.reducer;
import TitledInput from "../common/TitledInput";
import TitledSelect from "../common/TitledSelect";
import {
    businessTypeOptions,
    countryOptions,
    countryOptionsLimited,
    industryOptions,
    numberOfEmployeesOptions
} from "../../data/Options";
import {useDispatch, useSelector} from "react-redux";
import {
    onChangeAddress1Input,
    onChangeAddress2Input,
    onChangeBusinessSocialMediaLinkInput,
    onChangeBusinessTypeInput,
    onChangeBusinessWebsiteInput,
    onChangeCityInput,
    onChangeCompanyNameInput,
    onChangeCountryBusinessIsRegisteredInput,
    onChangeCountryInput,
    onChangeCountryOfOperationForThisEntityInput,
    onChangeEmailAddressInput,
    onChangeIndustryInput,
    onChangeNumberOfEmployeesInput,
    onChangePhoneNumberInput, onChangePostalCodeInput,
    onChangeStateInput
} from "../../redux/features/MyOrgSlice";
import {submitMyOrgFormFunction} from "./functions";

const ManageOrg =()=>{
    const dispatch = useDispatch();
    const {
        companyNameInput,
        emailAddressInput,
        phoneNumberInput,
        businessTypeInput,
        countryBusinessIsRegisteredInput,
        countryOfOperationForThisEntityInput,
        industryInput,
        numberOfEmployeesInput,
        businessWebsiteInput,
        businessSocialMediaLinkInput,
        address1Input,
        address2Input,
        cityInput,
        stateInput,
        countryInput,
        postalCodeInput
    } = useSelector((store)=> store.myOrg);

    const updateCompanyNameInput =(event)=> {
        dispatch(onChangeCompanyNameInput(event.target.value));
    }
    const updateEmailAddressInput =(event)=> {
        dispatch(onChangeEmailAddressInput(event.target.value));
    }
    const updatePhoneNumberInput =(event)=> {
        dispatch(onChangePhoneNumberInput(event.target.value));
    }
    const updateBusinessTypeInput =(value)=> {
        dispatch(onChangeBusinessTypeInput(value));
    }
    const updateCountryBusinessIsRegisteredInput =(value)=> {
        dispatch(onChangeCountryBusinessIsRegisteredInput(value));
    }
    const updateCountryOfOperationForThisEntityInput =(value)=> {
        dispatch(onChangeCountryOfOperationForThisEntityInput(value));
    }
    const updateIndustryInput =(value)=> {
        dispatch(onChangeIndustryInput(value));
    }
    const updateNumberOfEmployeesInput =(value)=> {
        dispatch(onChangeNumberOfEmployeesInput(value));
    }
    const updateBusinessWebsiteInput =(event)=> {
        dispatch(onChangeBusinessWebsiteInput(event.target.value));
    }
    const updateBusinessSocialMediaLinkInput =(event)=> {
        dispatch(onChangeBusinessSocialMediaLinkInput(event.target.value));
    }
    const updateAddress1Input =(event)=> {
        dispatch(onChangeAddress1Input(event.target.value));
    }
    const updateAddress2Input =(event)=> {
        dispatch(onChangeAddress2Input(event.target.value));
    }
    const updateCityInput =(event)=> {
        dispatch(onChangeCityInput(event.target.value));
    }
    const updateStateInput =(event)=> {
        dispatch(onChangeStateInput(event.target.value));
    }
    const updateCountryInput =(value)=> {
        dispatch(onChangeCountryInput(value));
    }
    const updatePostalCodeInput =(event)=> {
        dispatch(onChangePostalCodeInput(event.target.value));
    }
    const submitForm = () => {
        submitMyOrgFormFunction();
    }

    return (
        <div className={'manageOrg-container'}>

            <div className={'form-section-title'}>General Info</div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'company name'} placeholder={'company name'} value={companyNameInput}
                             onChange={updateCompanyNameInput}/>
            </div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'email address'} placeholder={'email address'} value={emailAddressInput}
                             onChange={updateEmailAddressInput}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'phone number'} placeholder={'phone number'} value={phoneNumberInput}
                             onChange={updatePhoneNumberInput}/>
            </div>

            <div className={'horizontalFlex'}>
                <TitledSelect title={'business type'} options={businessTypeOptions} value={businessTypeInput}
                              onChange={updateBusinessTypeInput}
                />
                <div className={'horizontalSplitter-10px'}></div>
                <TitledSelect title={'country business is registered'} options={countryOptions}
                              value={countryBusinessIsRegisteredInput}
                              onChange={updateCountryBusinessIsRegisteredInput}
                />
            </div>
            <div className={'horizontalFlex'}>
                <TitledSelect title={'country of operation for this entity'} options={countryOptionsLimited}
                              value={countryOfOperationForThisEntityInput}
                              onChange={updateCountryOfOperationForThisEntityInput}
                />
                <div className={'horizontalSplitter-10px'}></div>
                <div className={'flex1'}/>
            </div>

            <div className={'horizontalFlex'}>
                <TitledSelect title={'Industry'} options={industryOptions}
                              value={industryInput}
                              onChange={updateIndustryInput}

                />
                <div className={'horizontalSplitter-10px'}></div>
                <TitledSelect title={'number of employees'} options={numberOfEmployeesOptions}
                              value={numberOfEmployeesInput}
                              onChange={updateNumberOfEmployeesInput}
                />
            </div>

            <div className={'horizontalFlex'}>
                <TitledInput title={'business website'} placeholder={'business website'} value={businessWebsiteInput}
                             onChange={updateBusinessWebsiteInput}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'business social media link'} placeholder={'business social media link'}
                             value={businessSocialMediaLinkInput}
                             onChange={updateBusinessSocialMediaLinkInput}/>
            </div>

            <div className={'form-section-title'}>Address</div>
            <div className={'horizontalFlex'}>
                <TitledInput title={'address 1 '} placeholder={'address1'} value={address1Input}
                             onChange={updateAddress1Input}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'address 2'} placeholder={'address2'} value={address2Input}
                             onChange={updateAddress2Input}/>
            </div>
            <div className={'horizontalFlex'}>
                <TitledInput title={'city'} placeholder={'city'} value={cityInput}
                             onChange={updateCityInput}/>
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'state'} placeholder={'state'} value={stateInput}
                             onChange={updateStateInput}/>
            </div>
            <div className={'horizontalFlex'}>
                <TitledSelect title={'country of operation for this entity'} options={countryOptionsLimited}
                              value={countryInput}
                              onChange={updateCountryInput}
                />
                <div className={'horizontalSplitter-10px'}></div>
                <TitledInput title={'postal code'} placeholder={'postal code'} value={postalCodeInput}
                             onChange={updatePostalCodeInput}/>
            </div>

            <div className={'form-section-title'}>Legal/Operating Info</div>
            TIN
            NSSF ID

            <div className={'horizontalFlex'}>
                <div className={'flex1'}/>
                <div className={'formSubmit-green'} onClick={submitForm}>Submit</div>
            </div>

        </div>
    )
}

export default ManageOrg;
import React from 'react';
import Analytics from 'analytics';
import ReactDOM from 'react-dom/client';
import Body from "./components/Body";
import Login from "./components/landing/Login";
import SignUp from "./components/landing/SignUp";
import {Provider} from 'react-redux';
import {store} from "./redux/store";
import {injectStoreInMyOrgFunctions} from "./components/myOrg/functions";
import {AnalyticsProvider} from "use-analytics";
import {BrowserRouter as Routers, Switch, Route, Routes, HashRouter as Router, Navigate} from 'react-router-dom';
import history from "./history";
import {injectStoreInDeductionsAndContributionsFunctions} from "./components/deductionsAndContributions/functions";
import Dashboard from "./components/home/Dashboard";
import {
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS,
    SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES,
    SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES,
    SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES, SIDE_NAV_HOME_DASHBOARD,
    SIDE_NAV_MY_ORG_MANAGE_ORG,
    SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS,
    SIDE_NAV_MY_ORG_MANAGE_TAGS,
    SIDE_NAV_MY_ORG_VIEW_ORG,
    SIDE_NAV_PAYROLL_HISTORY,
    SIDE_NAV_PAYROLL_RUN_PAYROLL,
    TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS,
    TOP_NAV_EMPLOYEES, TOP_NAV_HOME,
    TOP_NAV_MY_ORG,
    TOP_NAV_PAYROLL
} from "./functions/constants";
import axios from "axios";


const root = ReactDOM.createRoot(document.getElementById('root'));
// inject store in different function files
injectStoreInMyOrgFunctions(store);
injectStoreInDeductionsAndContributionsFunctions(store);

const analytics = Analytics({
    app: 'awesome-app',
    plugins: [
        // googleAnalytics({
        //     trackingId: 'UA-1234567',
        // })
    ]
});

const authNAxiosInstance = axios.create({
    withCredentials: true,
    baseURL: "https://www.authn.fiigers.com"
})
const doIfLoggedIn = (props) => {
    let isLoggedIn = false; // check if we have a user in the redux. check everytime? or check once every x amount of time
    // to reduce the call. Maybe store a JWT with time it was set. Also send this JWT to backend which will determine
    // whether to request app to refresh credentials.

    // if JWT expired or not in redux state, make call to backend api to see if user logged in.
    let authNTicket = null;
    // if (authNTicket == null || authNTicket.expires > Date.now()) {
        if (authNTicket == null) {
        // make new call to backend and see if we will get a response
            try {
                getUserCall().then(res => {
                    console.log(res)
                });
            } catch (e) {
                alert("Exception found")
            }
        // we will pass this ticket to the backend which will determine if the user is still logged in by the time the request is sent
        // will need to refresh it for cases where it is not or go straight to FEBE (Front End - Back End) for that

    }
    // if logged in, proceed, otherwise, ask user to login


    return isLoggedIn ? props : <Navigate to={"/sign-in"} replace={true}/>
}
 //TODO move this to a common file
let getUserCall = () => {
    return authNAxiosInstance.get('/user', {
        headers : {
            'content-type': 'application/json'
        }
    }).then(res => {
        if (res != null && res.data != null) {

            console.log(res)

            // store.dispatch( (res.data.user));
            // // alert(JSON.stringify(res.data.user));
            // // store.dispatch(setOrgProfile(res.data.org));
            // if (res.data.user.activeOrg == null) {
            //     let { username, email, userId, address } = res.data.user
            //     defaultOrgProfile.name = username;
            //     defaultOrgProfile.email = email;
            //     defaultOrgProfile.id = userId;
            //     defaultOrgProfile.address = address;
            //
            //     store.dispatch(setOrgProfile(defaultOrgProfile));
            // } else {
            //     store.dispatch(setOrgProfile(res.data.org));
            // }

        }
    })
        .catch(error => {
            console.error(error);
        });;
}

root.render(
  <React.StrictMode>
      <AnalyticsProvider instance={analytics}>
          <Provider store={store}>
              <Router history={history}>
                  <Routes>
                      <Route path='/*' element={ doIfLoggedIn(<Body id={TOP_NAV_HOME}/>) }/>

                      <Route path='/in/*' element={ <Body id={TOP_NAV_HOME} /> }/>

                      {/*SIGN UP*/}
                      <Route path='/sign-up' element={ <SignUp/> }/>

                      {/*SIGN IN*/}
                      <Route path='/sign-in' element={ <Login/> }/>

                      {/*HOME*/}
                      <Route path='/in/home/v/*' element={ doIfLoggedIn(<Body id={TOP_NAV_HOME}/>) }/>
                      <Route path='/in/home/v/dashboard' element={ doIfLoggedIn(<Body id={TOP_NAV_HOME} side={SIDE_NAV_HOME_DASHBOARD}/>) }/>


                      {/*PAYROLL*/}
                      <Route path='/in/payroll/v/*' element={ doIfLoggedIn(<Body id={TOP_NAV_PAYROLL}/>) }/>
                      <Route path='/in/payroll/v/run-payroll' element={ doIfLoggedIn(<Body id={TOP_NAV_PAYROLL} side={SIDE_NAV_PAYROLL_RUN_PAYROLL}/>) }/>
                      <Route path='/in/payroll/v/history' element={ doIfLoggedIn(<Body id={TOP_NAV_PAYROLL} side={SIDE_NAV_PAYROLL_HISTORY}/>) }/>

                      {/*EMPLOYEES*/}
                      <Route path='/in/employees/v/*' element={ doIfLoggedIn(<Body id={TOP_NAV_EMPLOYEES}/>) }/>
                      <Route path='/in/employees/v/view' element={ doIfLoggedIn(<Body id={TOP_NAV_EMPLOYEES} side={SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES}/>) }/>
                      <Route path='/in/employees/v/add' element={ doIfLoggedIn(<Body id={TOP_NAV_EMPLOYEES} side={SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES}/>) }/>
                      <Route path='/in/employees/v/manage' element={ doIfLoggedIn(<Body id={TOP_NAV_EMPLOYEES} side={SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES}/>) }/>

                      {/*MY ORG*/}
                      <Route path='/in/my-org/v/*' element={ doIfLoggedIn(<Body id={TOP_NAV_MY_ORG}/>) }/>
                      <Route path='/in/my-org/v/view' element={ doIfLoggedIn(<Body id={TOP_NAV_MY_ORG} side={SIDE_NAV_MY_ORG_VIEW_ORG}/>) }/>
                      <Route path='/in/my-org/v/manage-org' element={ doIfLoggedIn(<Body id={TOP_NAV_MY_ORG} side={SIDE_NAV_MY_ORG_MANAGE_ORG}/>) }/>
                      <Route path='/in/my-org/v/manage-components' element={ doIfLoggedIn(<Body id={TOP_NAV_MY_ORG} side={SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS}/>) }/>
                      <Route path='/in/my-org/v/manage-tags' element={ doIfLoggedIn(<Body id={TOP_NAV_MY_ORG} side={SIDE_NAV_MY_ORG_MANAGE_TAGS}/>) }/>

                      {/*DEDUCTIONS AND CONTRIBUTIONS*/}
                      <Route path='/in/deductions-&-contributions/v/*' element={ doIfLoggedIn(<Body id={TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS}/>) }/>
                      <Route path='/in/deductions-&-contributions/v/manage-deductions' element={ doIfLoggedIn(<Body id={TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS} side={SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS}/>) }/>
                      <Route path='/in/deductions-&-contributions/v/manage-contributions' element={ doIfLoggedIn(<Body id={TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS} side={SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS}/>) }/>


                  </Routes>
                  {/*<Route path='/in/inbound/*' element={ <Inbound/> }/>*/}
                  {/*<Route path='/invoices/outbound/*' element={ <Outbound/> }/>*/}
                  {/*<Route path='/charts/*' element={ <Charts/> }/>*/}
              {/*<Body/>*/}
              {/*   <SignUp/>*/}
              </Router>
          </Provider>
      </AnalyticsProvider>
  </React.StrictMode>
);


import {createSlice} from "@reduxjs/toolkit";
import _ from 'lodash';

let initialState = {
    orgSnapshot: {},
    userSnapshot: {},
    componentSnapshot: [],
    tagSnapshot: [],
    deductionsSnapshot: [],
    contributionsSnapshot: [],
    preferences: {
        employeeViewOrder: [],
        payrollCSVOrder: []
    },
    employeesSnapshot: []
}

const snapshotSlice = createSlice({
    name: "snapshot",
    initialState,
    reducers : {
        updateOrgSnapShot: (state, action) => {
            state.orgSnapshot = action.payload;
        },
        appendPayComponentToOrgSnapShot: (state, action) => {
            let componentState = state.componentSnapshot;
            componentState.push(action.payload);
            state.componentSnapshot = componentState;
        },
        findAndUpdatePayComponentToOrgSnapShot: (state, action) => {
            let componentState = state.componentSnapshot;
            let index = _.findIndex(componentState,(item) => item.id === action.payload.id);
            componentState[index] = action.payload;
            state.componentSnapshot = componentState;
        },
        appendTagToOrgSnapShot: (state, action) => {
            let tagState = state.tagSnapshot;
            tagState.push(action.payload);
            state.tagSnapshot = tagState;
        },
        findAndUpdateTagToOrgSnapShot: (state, action) => {
            let tagState = state.tagSnapshot;
            let index = _.findIndex(tagState,(item) => item.id === action.payload.id);
            tagState[index] = action.payload;
            state.tagSnapshot = tagState;
        },
        appendDeductionToOrgSnapShot: (state, action) => {
            let deductionsState = state.deductionsSnapshot;
            deductionsState.push(action.payload);
            state.deductionsSnapshot = deductionsState;
        },
        updatePreferencesSnapshot: (state, action) => {
            if (action.payload.type === "employeeViewOrder") {
                state.preferences.employeeViewOrder = action.payload.data;
            } else {
                state.preferences.payrollCSVOrder = action.payload.data;
            }
        },
        setEmployeesSnapshot: (state, action) => {
            state.employeesSnapshot = action.payload;
        },
        appendToEmployeeSnapshot: (state, action) => {
            let employeesState = state.employeesSnapshot;
            employeesState.push(action.payload)
            state.employeesSnapshot = employeesState;
        }
    },
    // extraReducers: (builder) => {
    //     builder.addCase(setUserProfile, (state, action)=>{
    //         state.userSnapshot = action.payload;
    //     });
    //     builder.addCase(setOrgProfile, (state, action)=>{
    //         state.orgSnapshot = action.payload;
    //     });
    // }
});

export const {updateOrgSnapShot, appendPayComponentToOrgSnapShot, findAndUpdatePayComponentToOrgSnapShot,
    appendTagToOrgSnapShot, findAndUpdateTagToOrgSnapShot, appendDeductionToOrgSnapShot, updatePreferencesSnapshot,
    setEmployeesSnapshot, appendToEmployeeSnapshot} = snapshotSlice.actions;
export default snapshotSlice.reducer;
import {createSlice} from "@reduxjs/toolkit";

let initialState = {
    // manage org
    companyNameInput: "",
    emailAddressInput: "",
    phoneNumberInput: "",
    businessTypeInput: "",
    countryBusinessIsRegisteredInput: "",
    countryOfOperationForThisEntityInput: "",
    industryInput: "",
    numberOfEmployeesInput: "",
    businessWebsiteInput: "",
    businessSocialMediaLinkInput: "",
    address1Input: "",
    address2Input: "",
    cityInput: "",
    stateInput: "",
    countryInput: "",
    postalCodeInput: "",
    manageOrgErrorInputMessages: {
        companyName: null,
        emailAddress: null,
        phoneNumber: null,
        businessType: null,
        countryBusinessIsRegistered: null,
        countryOfOperationForThisEntity: null,
        industry: null,
        numberOfEmployees: null,
        businessWebsite: null,
        businessSocialMediaLink: null,
        address1Input: null,
        address2Input: null,
        cityInput: null,
        stateInput: null,
        countryInput: null,
        postalCodeInput: null,
    },
    // manage components
    addPayComponentInput: "",
    addPayComponentDefaultValueInput: null,
    addPayComponentRequiredInput: false,
    addPayComponentDescriptionInput: "",
    isEditingPayComponent: false,
    selectedPayComponent: null,

    // manage tags
    addTagInput: "",
    addTagRequiredInput: false,
    addTagDescriptionInput: "",
    isEditingTag: false,
    selectedTag: null,

}
const myOrgSlice = createSlice({
    name: "myOrg",
    initialState,
    reducers: {
        onChangeCompanyNameInput: (state, action) => {state.companyNameInput = action.payload;},
        onChangeEmailAddressInput: (state, action) => {state.emailAddressInput = action.payload;},
        onChangePhoneNumberInput: (state, action) => {state.phoneNumberInput = action.payload;},
        onChangeBusinessTypeInput: (state, action) => {state.businessTypeInput = action.payload;},
        onChangeCountryBusinessIsRegisteredInput: (state, action) => {state.countryBusinessIsRegisteredInput = action.payload;},
        onChangeCountryOfOperationForThisEntityInput: (state, action) => {state.countryOfOperationForThisEntityInput = action.payload;},
        onChangeIndustryInput: (state, action) => {state.industryInput = action.payload;},
        onChangeNumberOfEmployeesInput: (state, action) => {state.numberOfEmployeesInput = action.payload;},
        onChangeBusinessWebsiteInput: (state, action) => {state.businessWebsiteInput = action.payload;},
        onChangeBusinessSocialMediaLinkInput: (state, action) => {state.businessSocialMediaLinkInput = action.payload;},
        onChangeAddress1Input: (state, action) => {state.address1Input = action.payload;},
        onChangeAddress2Input: (state, action) => {state.address2Input = action.payload;},
        onChangeCityInput: (state, action) => {state.cityInput = action.payload;},
        onChangeStateInput: (state, action) => {state.stateInput = action.payload;},
        onChangeCountryInput: (state, action) => {state.countryInput = action.payload;},
        onChangePostalCodeInput: (state, action) => {state.postalCodeInput = action.payload;},

        // manage Pay Components
        onChangeAddPayComponentInput: (state, action) => {state.addPayComponentInput = action.payload;},
        onChangeAddPayComponentDefaultValueInput: (state, action) => {state.addPayComponentDefaultValueInput = action.payload;},
        onChangeAddPayComponentRequiredInput: (state, action) => {state.addPayComponentRequiredInput = action.payload;},
        onChangeAddPayComponentDescriptionInput: (state, action) => {state.addPayComponentDescriptionInput = action.payload;},
        onChangeIsEditingPayComponent: (state, action) => {state.isEditingPayComponent = action.payload;},
        onChangeSelectedPayComponent: (state, action) => {
            state.selectedPayComponent = action.payload;
            state.addPayComponentInput = action.payload.payComponent;
            state.addPayComponentDefaultValueInput = action.payload.defaultValue;
            state.addPayComponentRequiredInput = action.payload.required;
            state.addPayComponentDescriptionInput = action.payload.description;
            },
        onClearInputManageComponents: (state, action) => {
            state.addPayComponentInput = "";
            state.addPayComponentDefaultValueInput = "";
            state.addPayComponentRequiredInput = false;
            state.addPayComponentDescriptionInput = "";
            state.isEditingPayComponent = false;
            state.selectedPayComponent = null;
        },
        // manage Tags
        onChangeAddTagInput: (state, action) => {state.addTagInput = action.payload;},
        onChangeAddTagRequiredInput: (state, action) => {state.addTagRequiredInput = action.payload;},
        onChangeAddTagDescriptionInput: (state, action) => {state.addTagDescriptionInput = action.payload;},
        onChangeIsEditingTag: (state, action) => {state.isEditingTag = action.payload;},
        onChangeSelectedTag: (state, action) => {
            state.selectedTag = action.payload;
            state.addTagInput = action.payload.tag;
            state.addTagRequiredInput = action.payload.required;
            state.addTagDescriptionInput = action.payload.description;
        },
        onClearInputManageTags: (state, action) => {
            state.addTagInput = "";
            state.addTagRequiredInput = false;
            state.addTagDescriptionInput = "";
            state.isEditingTag = false;
            state.selectedTag = null;
        }


        // handle email validation and error messages when invalid in
        // errorMessage object which can inform ui of border-color and info bubble
        // handle required fields
        // error messages (not a valid email, required field,)

    }
});

export const {
    onChangeCompanyNameInput,
    onChangeEmailAddressInput,
    onChangePhoneNumberInput,
    onChangeBusinessTypeInput,
    onChangeCountryBusinessIsRegisteredInput,
    onChangeCountryOfOperationForThisEntityInput,
    onChangeIndustryInput,
    onChangeNumberOfEmployeesInput,
    onChangeBusinessWebsiteInput,
    onChangeBusinessSocialMediaLinkInput,
    onChangeAddress1Input,
    onChangeAddress2Input,
    onChangeCityInput,
    onChangeStateInput,
    onChangeCountryInput,
    onChangePostalCodeInput,
    onChangeAddPayComponentInput,
    onChangeAddPayComponentDefaultValueInput,
    onChangeAddPayComponentRequiredInput,
    onChangeAddPayComponentDescriptionInput,
    onChangeIsEditingPayComponent,
    onChangeSelectedPayComponent,
    onClearInputManageComponents,
    onChangeAddTagInput,
    onChangeAddTagRequiredInput,
    onChangeAddTagDescriptionInput,
    onChangeIsEditingTag,
    onChangeSelectedTag,
    onClearInputManageTags,

} = myOrgSlice.actions;
export default myOrgSlice.reducer;


import {createSlice} from "@reduxjs/toolkit";
import _ from 'lodash';

let initialState = {
    firstNameInput: "",
    lastNameInput: "",
    birthdateInput: "",
    genderInput: "",
    emailAddressInput: "",
    address1Input: "",
    address2Input: "",
    cityInput: "",
    stateInput: "",
    countryInput: "",
    postalCodeInput: "",
    mobileInput: "",
    personalEmailInput: "",
    identificationInputs: [],
    tagInputs: {},
    payComponentInputs: {},
    deductionsAndContributionInputs: [],
    showViewEmployeesCustomizer: false,
}


const employeeSlice = createSlice({
    name: 'employeeSlice',
    initialState,
    reducers: {
        onChangeFirstNameInput: (state, action) => {state.firstNameInput = action.payload;},
        onChangeLastNameInput: (state, action) => {state.lastNameInput = action.payload;},
        onChangeBirthdateInput: (state, action) => {state.birthdateInput = action.payload;},
        onChangeGenderInput: (state, action) => {state.genderInput = action.payload;},
        onChangeEmailAddressInput: (state, action) => {state.emailAddressInput = action.payload;},
        onChangeAddress1Input: (state, action) => {state.address1Input = action.payload;},
        onChangeAddress2Input: (state, action) => {state.address2Input = action.payload;},
        onChangeCityInput: (state, action) => {state.cityInput = action.payload;},
        onChangeStateInput: (state, action) => {state.stateInput = action.payload;},
        onChangeCountryInput: (state, action) => {state.countryInput = action.payload;},
        onChangePostalCodeInput: (state, action) => {state.postalCodeInput = action.payload;},
        onChangeMobileInput: (state, action) => {state.mobileInput = action.payload;},
        onChangePersonalEmailInput: (state, action) => {state.personalEmailInput = action.payload;},
        onChangeTagInputs: (state, action) => {
            let tagInputsState = state.tagInputs;
            // if empty or index doesn't exist, create it, else update existing
            if (tagInputsState[action.payload.id]) {
                tagInputsState[action.payload.id].value = action.payload.value;
                return;
            }

            if (!tagInputsState || Object.keys(tagInputsState).length === 0 || !tagInputsState[action.payload.id]) {
                const id = action.payload.id;
                // let toInsert = {}
                // toInsert[id] = {
                //     id,
                //     value: action.payload.value
                // }
                state.tagInputs[id] = {
                    id,
                    value: action.payload.value
                }
            }
        },
        onChangePayComponentInputs: (state, action) => {
            let payComponentInputsState = state.payComponentInputs;
            // if empty or index doesn't exist, create it, else update existing
            if (payComponentInputsState[action.payload.id]) {
                payComponentInputsState[action.payload.id].value = action.payload.value;
                return;
            }

            if (!payComponentInputsState || Object.keys(payComponentInputsState).length === 0 || !payComponentInputsState[action.payload.id]) {
                const id = action.payload.id;
                state.payComponentInputs[id] = {
                    id,
                    value: action.payload.value
                }
            }
        },
        payComponentInputs: [],
        deductionsAndContributionInputs: [],

        onChangeIdentificationInputs: (state, action) => {
            state.postalCodeInput = action.payload;
        },
        toggleShowViewEmployeesCustomizer: (state, action) => {
            state.showViewEmployeesCustomizer = action.payload;
        }
    }
});

export const {
    onChangeFirstNameInput,
    onChangeLastNameInput,
    onChangeBirthdateInput,
    onChangeGenderInput,
    onChangeEmailAddressInput,
    onChangeAddress1Input,
    onChangeAddress2Input,
    onChangeCityInput,
    onChangeStateInput,
    onChangeCountryInput,
    onChangePostalCodeInput,
    onChangeMobileInput,
    onChangePersonalEmailInput,
    onChangeTagInputs,
    onChangePayComponentInputs,
    toggleShowViewEmployeesCustomizer
} = employeeSlice.actions;
export default employeeSlice.reducer;
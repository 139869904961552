import FlexBlocker from "../common/FlexBlocker";
import {CheckmarkIcon} from "../../resources/icons";
import Checkbox from "../common/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {
    onChangeAddPayComponentDefaultValueInput, onChangeAddPayComponentDescriptionInput,
    onChangeAddPayComponentInput,
    onChangeAddPayComponentRequiredInput, onChangeIsEditingPayComponent, onChangeSelectedPayComponent
} from "../../redux/features/MyOrgSlice";
import {saveChangesToComponentFunction, submitAddPayComponentFormFunction} from "./functions";
import RowTile from "../common/RowTile";

const ManageComponents =(props)=> {
    const dispatch = useDispatch();

    const {
        addPayComponentInput,
        addPayComponentDefaultValueInput,
        addPayComponentRequiredInput,
        addPayComponentDescriptionInput,
        isEditingPayComponent,
        selectedPayComponent
    } = useSelector((store) => store.myOrg);
    const {componentSnapshot} = useSelector((store)=> store.snapshot);

    const updateAddPayComponent = (event) => {
        dispatch(onChangeAddPayComponentInput(event.target.value));
    }
    const updateAddPayComponentDefaultValue = (event) => {
        dispatch(onChangeAddPayComponentDefaultValueInput(event.target.value));
    }
    const updateAddPayComponentRequired = (state) => {
        dispatch(onChangeAddPayComponentRequiredInput(!state));
    }
    const updateAddPayComponentDescription = (event) => {
        dispatch(onChangeAddPayComponentDescriptionInput(event.target.value));
    }
    const updateIsEditingPayComponent = (state) => {
        dispatch(onChangeIsEditingPayComponent(!state));
    }
    const updateSelectedPayComponent = (item) => {
        dispatch(onChangeIsEditingPayComponent(true));
        dispatch(onChangeSelectedPayComponent(item));
    }

    const submitForm = () => {
        submitAddPayComponentFormFunction();
    }

    const saveChangesToComponent = () => {
        saveChangesToComponentFunction();
    }

    const renderRowTiles = () => {
        let data_ = componentSnapshot;
        return data_.map( item =>
            <RowTile required={item.required} label={item.payComponent} defaultValue={item.defaultValue}
                     description={item.description} onClickEdit={()=>{updateSelectedPayComponent(item)}}/>
        );
    }


    return (
        <div>
            <div className={'horizontalFlex darkContainer'}>
                <div className={'verticalFlex'}>
                    <div className={'input-manageComponents-add-container'}>
                        <div className={'input-addComponent white-background stack-border-radius-top'}>
                            <input
                                className={'input'}
                                placeholder={'Add pay component'}
                                value={addPayComponentInput}
                                onChange={updateAddPayComponent}
                            />
                        </div>
                        <div className={'horizontalSplitter'}/>

                        <div className={'input-addComponent white-background stack-border-radius-bottom'}>
                            <input
                                className={'input'}
                                placeholder={'Optional: Default value'}
                                value={addPayComponentDefaultValueInput}
                                onChange={updateAddPayComponentDefaultValue}
                            />
                        </div>
                    </div>
                    <div className={'verSplitter-10px'}></div>

                    <div className={`formSubmit-green ${isEditingPayComponent ? 'editing':''}`}
                         onClick={isEditingPayComponent ? saveChangesToComponent : submitForm}>
                        {isEditingPayComponent ? "Save" : "+ Add"}
                    </div>

                    <div className={'flex1'}></div>
                    <div className={'flex1 horizontalFlex manageComponent-required-container'}>
                        <Checkbox title={'Required?'}
                                  value={addPayComponentRequiredInput}
                                  onClick={updateAddPayComponentRequired}/>
                    </div>

                </div>
                <div className={'flex1 white-background textarea-description'}>
                    <textarea
                        className={'textarea'}
                        placeholder={'Optional: description'}
                        value={addPayComponentDescriptionInput}
                        onChange={updateAddPayComponentDescription}
                    />
                </div>
            </div>
            <div className={'bdx-100'}></div>
            {renderRowTiles()}

            {/*{JSON.stringify(componentSnapshot)}*/}

            {/*<div className={'listTile listTile-component horizontalFlex'}>*/}
            {/*    <div className={'listTile-component-dot required'}></div>*/}
            {/*    <div className={'verticalFlex'}>*/}
            {/*        <div className={'listTile-component-title'}>Base Pay</div>*/}
            {/*        <div className={'listTile-component-notes'}>Base or Basic pay for employee.</div>*/}
            {/*    </div>*/}
            {/*    <div className={'flex1'}/>*/}
            {/*    <div className={'horizontalFlex'}>*/}
            {/*        <div className={'listTile-component-buttons pointer'}>*/}
            {/*            <div className={'button'}>*/}
            {/*                Edit*/}
            {/*            </div>*/}
            {/*            <div className={'splitter-xsd'}/>*/}
            {/*            <div className={'button'}>Delete</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*<div className={'listTile listTile-component horizontalFlex'}>*/}
            {/*    <div className={'listTile-component-dot'}></div>*/}
            {/*    <div className={'verticalFlex'}>*/}
            {/*        <div className={'listTile-component-title'}>Housing Allowance</div>*/}
            {/*        <div className={'listTile-component-notes'}>No notes</div>*/}
            {/*    </div>*/}
            {/*    <div className={'flex1'}/>*/}
            {/*    <div className={'horizontalFlex'}>*/}
            {/*        <div className={'listTile-component-buttons pointer'}>*/}
            {/*            <div className={'button'}>*/}
            {/*                Edit*/}
            {/*            </div>*/}
            {/*            <div className={'splitter-xsd'}/>*/}
            {/*            <div className={'button'}>Delete</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*<div className={'listTile listTile-component horizontalFlex'}>*/}
            {/*    <div className={'listTile-component-dot'}></div>*/}
            {/*    <div className={'verticalFlex'}>*/}
            {/*        <div className={'listTile-component-title'}>Fuel Allowance</div>*/}
            {/*        <div className={'listTile-component-notes'}>No notes</div>*/}
            {/*    </div>*/}
            {/*    <div className={'flex1'}/>*/}
            {/*    <div className={'horizontalFlex'}>*/}
            {/*        <div className={'listTile-component-buttons pointer'}>*/}
            {/*            <div className={'button'}>*/}
            {/*                Edit*/}
            {/*            </div>*/}
            {/*            <div className={'splitter-xsd'}/>*/}
            {/*            <div className={'button'}>Delete</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*<div className={'listTile listTile-component horizontalFlex'}>*/}
            {/*    <div className={'listTile-component-dot required'}></div>*/}
            {/*    <div className={'verticalFlex'}>*/}
            {/*        <div className={'listTile-component-title'}>Incentive Pay</div>*/}
            {/*        <div className={'listTile-component-notes'}>...</div>*/}
            {/*    </div>*/}
            {/*    <div className={'flex1'}/>*/}
            {/*    <div className={'horizontalFlex'}>*/}
            {/*        <div className={'listTile-component-buttons pointer'}>*/}
            {/*            <div className={'button'}>*/}
            {/*                Edit*/}
            {/*            </div>*/}
            {/*            <div className={'splitter-xsd'}/>*/}
            {/*            <div className={'button'}>Delete</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*<div className={'listTile listTile-component horizontalFlex'}>*/}
            {/*    <div className={'listTile-component-dot required'}></div>*/}
            {/*    <div className={'verticalFlex'}>*/}
            {/*        <div className={'listTile-component-title'}>Profession tax</div>*/}
            {/*        <div className={'listTile-component-notes'}>...</div>*/}
            {/*    </div>*/}
            {/*    <div className={'flex1'}/>*/}
            {/*    <div className={'horizontalFlex'}>*/}
            {/*        <div className={'listTile-component-buttons pointer'}>*/}
            {/*            <div className={'button'}>*/}
            {/*                Edit*/}
            {/*            </div>*/}
            {/*            <div className={'splitter-xsd'}/>*/}
            {/*            <div className={'button'}>Delete</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}
            <div className={'listTile'}/>
            <div className={'listTile'}/>
            <div className={'listTile'}/>
        </div>
    )

}

export default ManageComponents;
import {useRef, useState} from "react";
// import users_ from './users.json';
import employeeComponents from '../../resources/employeeComponents.json'
import Checkbox from "../common/Checkbox";
import _ from "lodash";
import {toggleShowViewEmployeesCustomizer} from "../../redux/features/EmployeeSlice";
import {useDispatch, useSelector} from "react-redux";
import {BarsIcon} from "../../resources/icons";
import {updatePreferencesSnapshot} from "../../redux/features/SnapshotSlice";

const ViewEmployeesCustomizer = () => {
    const dispatch = useDispatch();
    const { preferences } = useSelector((store) => store.snapshot);
    //create local context for column fields
    const MAX_SELECTION = 6;
    let preferences_ = preferences && preferences.employeeViewOrder.length > 0 ?
        preferences.employeeViewOrder: employeeComponents.default;

    let default_ = employeeComponents.default;
    const preferencesOrDefault  = preferences_ == null || preferences_.length == 0 ? default_ : preferences_;
    const [localContextOrder, setLocalContextOrder] = useState(preferencesOrDefault);
    const [components, setComponents] = useState(employeeComponents);


    // construct set with selectedItems
    // extract into array
    let ids = preferencesOrDefault.map(item => "" + item.label + item.membership);
    const initSet = new Set(ids);

    const [selectedItemsSet, setSelectedItemsSet] = useState(initSet);


    const removeSelection = (membership, label) => {
        // check for existence in selectedItemsSet
        let id = "" + label + membership;
        if (!selectedItemsSet.has(id)) return;

        // remove from selectedItemsSet
        // let currSetState = selectedItemsSet;
        selectedItemsSet.delete(id);

        // remove from localContextOrder
        let localContextOrder_ = [...localContextOrder];
        _.remove(localContextOrder_, (item) => item.label === label && item.membership === membership );
        setLocalContextOrder(localContextOrder_);

    }
    const toggleSelection = (membership, label, item) => {
        let id = "" + label + membership;
        // if exists, remove
        if (selectedItemsSet.has(id)) {
            removeSelection(membership, label);
        } else {
            // if selected items are at max length, do nothing
            if (selectedItemsSet.size == MAX_SELECTION) return;
            // add to selectedItemsSet
            let selectedItemSet_ = selectedItemsSet;
            selectedItemSet_.add(id);
            setSelectedItemsSet(new Set(selectedItemSet_));

            // add to localOrderContext
            let localOrderContext_ = [...localContextOrder];
            localOrderContext_.push(item);
            setLocalContextOrder(localOrderContext_);

        }
    }

    //save reference for dragItem and dragOverItem
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    //handle drag sorting
    const handleSort = () => {
        // duplicate items
        let _item = [...localContextOrder]

        //remove and save the dragged item content
        const draggedItemContent = _item.splice(dragItem.current, 1)[0];

        //switch the position
        _item.splice(dragOverItem.current, 0, draggedItemContent);

        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;

        // update the actual array
        setLocalContextOrder(_item);
    }

    const isChecked = (membership, label) => {
        let id = "" + label + membership;
        return selectedItemsSet.has(id);
    }
    const closeCustomizer = () => {
        dispatch(toggleShowViewEmployeesCustomizer(false))
    }

    const updatePreferences = () => {
        let payload = {
            type: "employeeViewOrder",
            data: [...localContextOrder]
        }
        dispatch(updatePreferencesSnapshot(payload));
        closeCustomizer();
    }


    return (
        <div className={'createDeductionsBlocker'}>
            <div className={'createDeductionsBackground'} onClick={()=>{closeCustomizer(false)}}/>
        <div className={'listColumnSelector'}>
            <div className={'listColSelectorDaughter'}>
                {/*<div>SELECT DATA</div>*/}

                <div className={'employeeCustomizerListSection'}>SELECTED</div>

                {localContextOrder.map((item, index) =>
                    <div className={'employeeCustomizerListTile ToOrderListItems'}
                         key={index}
                         draggable={true}
                         onDragStart={(e) => dragItem.current=index}
                         onDragEnter={(e) => dragOverItem.current=index}
                         onDragEnd={handleSort}
                         onDragOver={(e) => e.preventDefault()}
                    >
                        <Checkbox value={true} onClick={()=>{removeSelection(item.membership, item.label)}}/>

                        <div className={'employeeCustomizerListTile-text'}>{item.label}</div>
                        <div className={'flex1'}/> <BarsIcon/>
                    </div>
                )}


                <div className={'employeeCustomizerListSection'}>GENERAL INFO</div>
                {components.generalInfo.map((item, index) =>
                    <div className={'employeeCustomizerListTile ToOrderListItems'}
                         key={index}
                    >
                        <Checkbox value={isChecked(item.membership, item.label)}
                                  onClick={()=>{toggleSelection(item.membership, item.label, item)}}/>
                        <div className={'employeeCustomizerListTile-text'}>{item.label}</div>

                        <div className={'flex1'}/>
                    </div>
                )}
                <div className={'employeeCustomizerListSection'}>PERSONAL INFO</div>
                {components.personalInfo.map((item, index) =>
                    <div className={'employeeCustomizerListTile ToOrderListItems'}
                         key={index}
                    >
                        <Checkbox value={isChecked(item.membership, item.label)}
                                  onClick={()=>{toggleSelection(item.membership, item.label, item)}}/>
                        <div className={'employeeCustomizerListTile-text'}>{item.label}</div>

                        <div className={'flex1'}/>
                    </div>
                )}
                <div className={'employeeCustomizerListSection'}>IDENTIFICATION INFO</div>
                {components.identificationInfo.map((item, index) =>
                    <div className={'employeeCustomizerListTile ToOrderListItems'}
                         key={index}
                    >
                        <Checkbox value={isChecked(item.membership, item.label)}
                                  onClick={()=>{toggleSelection(item.membership, item.label, item)}}/>

                        {item.label}
                        <div className={'flex1'}/>
                    </div>
                )}
                <div className={'employeeCustomizerListSection'}>EMPLOYMENT INFO</div>
                {components.employmentInfo.map((item, index) =>
                    <div className={'employeeCustomizerListTile ToOrderListItems'}
                         key={index}
                    >
                        <Checkbox value={isChecked(item.membership, item.label)}
                                  onClick={()=>{toggleSelection(item.membership, item.label, item)}}/>
                        <div className={'employeeCustomizerListTile-text'}>{item.label}</div>

                        <div className={'flex1'}/>
                    </div>
                )}
                <div className={'employeeCustomizerListSection'}>PAYMENT INFO</div>
                {/*{components.paymentInfo.map((item, index) =>*/}
                {/*    <div className={'employeeCustomizerListTile ToOrderListItems'}*/}
                {/*         key={index}*/}
                {/*    >*/}
                {/*        <Checkbox value={isChecked(item.membership, item.label)}*/}
                {/*                  onClick={()=>{toggleSelection(item.membership, item.label, item)}}/>*/}
                {/*        <div className={'employeeCustomizerListTile-text'}>{item.label}</div>*/}

                {/*        <div className={'flex1'}/>*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className={'employeeCustomizerListSection'}>LABELS</div>

                <div className={'employeeCustomizerListSection'}>PAY COMPONENTS</div>
                {/*{components.employmentInfo.map((item, index) =>*/}
                {/*    <div className={'employeeCustomizerListTile ToOrderListItems'}*/}
                {/*         key={index}*/}
                {/*    >*/}
                {/*        <Checkbox value={isChecked(item.membership, item.label)}*/}
                {/*                  onClick={()=>{toggleSelection(item.membership, item.label, item)}}/>*/}

                {/*        <div className={'employeeCustomizerListTile-text'}>{item.label}</div>*/}

                {/*        <div className={'flex1'}/>*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className={'employeeCustomizerListSection'}>DEDUCTIONS</div>
                {/*{components.generalInfo.map((item, index) =>*/}
                {/*    <div className={'employeeCustomizerListTile ToOrderListItems'}*/}
                {/*         key={index}*/}
                {/*    >*/}
                {/*        <Checkbox value={isChecked(item.membership, item.label)}*/}
                {/*                  onClick={()=>{toggleSelection(item.membership, item.label, item)}}/>*/}

                {/*        <div className={'employeeCustomizerListTile-text'}>{item.label}</div>*/}

                {/*        <div className={'flex1'}/>*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className={'employeeCustomizerListSection'}>CONTRIBUTIONS</div>

                <div style={{height: '80px', flexShrink:'0'}}></div>


                <div className={'employeeCustomizerSaveButton horizontalFlex'}>
                    {/*<div className={'flex1'}/>*/}
                    <div className={'formSubmit-green flex1'} onClick={updatePreferences}>Save changes</div>
                </div>

            </div>

            <div>

            </div>

            </div>

        </div>
    );
}
export default ViewEmployeesCustomizer;
import {
    appendPayComponentToOrgSnapShot, appendTagToOrgSnapShot,
    findAndUpdatePayComponentToOrgSnapShot, findAndUpdateTagToOrgSnapShot,
    updateOrgSnapShot
} from "../../redux/features/SnapshotSlice";
import uuid from 'uuid-random';
import {onClearInputManageComponents, onClearInputManageTags} from "../../redux/features/MyOrgSlice";
let store;
export const injectStoreInMyOrgFunctions = _store => {
    store = _store;
}

export const submitMyOrgFormFunction =()=> {
    // make call to api
    // on successful api call, update the snapshot
    let data = store.getState().myOrg;
    let data_ = packageMyOrgInputsForOrgSnapshot(data);
    store.dispatch(updateOrgSnapShot(data_));
}

export const submitAddPayComponentFormFunction =()=> {
    // make call to api
    // on successful api call, update the snapshot ?? generate ID??
    let data = store.getState().myOrg;
    let data_ = packagePayComponentInputsForOrgSnapshot(data);
    store.dispatch(appendPayComponentToOrgSnapShot(data_));
    store.dispatch(onClearInputManageComponents());
}

export const saveChangesToComponentFunction = () => {
    // make call to api : updatePayComponent (id, object)
    // on successful api call, update the snapshot
    let data = store.getState().myOrg;
    let data_ = packagePayComponentInputsForOrgSnapshot(data);
    store.dispatch(findAndUpdatePayComponentToOrgSnapShot(data_));
    store.dispatch(onClearInputManageComponents());
}

export const submitAddTagFormFunction = () => {
    // make call to api
    // on successful api call, update the snapshot ?? generate ID??
    let data = store.getState().myOrg;
    let data_ = packageTagInputsForOrgSnapshot(data);
    store.dispatch(appendTagToOrgSnapShot(data_));
    store.dispatch(onClearInputManageTags());
}

export const saveChangesToTagFunction = () => {
    // make call to api : updatePayComponent (id, object)
    // on successful api call, update the snapshot

    let data = store.getState().myOrg;
    let data_ = packageTagInputsForOrgSnapshot(data);
    store.dispatch(findAndUpdateTagToOrgSnapShot(data_));
    store.dispatch(onClearInputManageTags());
}




const packagePayComponentInputsForOrgSnapshot = data => {
    return  {
        id: !data.selectedPayComponent || !data.selectedPayComponent.id ? uuid() : data.selectedPayComponent.id,
        payComponent: data.addPayComponentInput,
        defaultValue: data.addPayComponentDefaultValueInput,
        description: data.addPayComponentDescriptionInput,
        required: data.addPayComponentRequiredInput
    }
}

const packageTagInputsForOrgSnapshot = data => {
    return  {
        id: !data.selectedTag || !data.selectedTag.id ? uuid() : data.selectedTag.id,
        tag: data.addTagInput,
        description: data.addTagDescriptionInput,
        required: data.addTagRequiredInput
    }
}

const packageMyOrgInputsForOrgSnapshot =(obj)=> {
    return {
        companyName: obj.companyNameInput,
        emailAddress: obj.emailAddressInput,
        phoneNumber: obj.phoneNumberInput,
        businessType: obj.businessTypeInput,
        countryBusinessIsRegistered: obj.countryBusinessIsRegisteredInput,
        countryOfOperationForThisEntity: obj.countryOfOperationForThisEntityInput,
        industry: obj.industryInput,
        numberOfEmployees: obj.numberOfEmployeesInput,
        businessWebsite: obj.businessWebsiteInput,
        businessSocialMediaLink: obj.businessSocialMediaLinkInput,
        address1: obj.address1Input,
        address2: obj.address2Input,
        city: obj.cityInput,
        state: obj.stateInput,
        country: obj.countryInput,
        postalCode: obj.postalCodeInput
    }
}
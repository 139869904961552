import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showCreateDeductionModal: false,
    addDeductionNameInput: '',
    addDeductionTypeInput: {},
    addDeductionAmountInput: '',
    addDeductionPeriodInput: {},
    addDeductionMaxPerYearInput: '',
    addDeductionRequiredInput: {},
    addDeductionSelectedComponentsInput: [],
    isEditingDeduction: false,
    selectedDeduction: {}


}

const deductionsAndContributionsSlice = createSlice({
    name: 'deductionsAndContributionsSlice',
    initialState,
    reducers: {
        toggleCreateDeductionModal: (state, action) => {
            state.showCreateDeductionModal = !action.payload;
        },
        onChangeNameInput: (state, action) => {
            state.addDeductionNameInput = action.payload;
        },
        onChangeTypeInput: (state, action) => {
            state.addDeductionTypeInput = action.payload;
        },
        onChangeAmountInput: (state, action) => {
            state.addDeductionAmountInput = action.payload;
        },
        onChangePeriodInput: (state, action) => {
            state.addDeductionPeriodInput = action.payload;
        },
        onChangeMaxPerYearInput: (state, action) => {
            state.addDeductionMaxPerYearInput = action.payload;
        },
        onChangeRequiredInput: (state, action) => {
            state.addDeductionRequiredInput = action.payload;
        },
        onChangeSelectedComponentsInput: (state, action) => {
            //action to add or remove
            state.addDeductionSelectedComponentsInput = action.payload; // push to array
        },
        onChangeIsEditingDeduction: (state, action) => {
            state.isEditingDeduction = action.payload;
        },
        onChangeSelectedDeduction: (state, action) => {
            state.selectedDeduction = action.payload;
        },
        onClearInputManageDeductions: () => initialState
    }
});

export const {
    toggleCreateDeductionModal,
    onChangeNameInput,
    onChangeTypeInput,
    onChangeAmountInput,
    onChangePeriodInput,
    onChangeMaxPerYearInput,
    onChangeRequiredInput,
    onChangeSelectedComponentsInput,
    onChangeIsEditingDeduction,
    onChangeSelectedDeduction,
    onClearInputManageDeductions
} =  deductionsAndContributionsSlice.actions;
export default deductionsAndContributionsSlice.reducer;
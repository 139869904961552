import TitledInput from "../common/TitledInput";
import logo from "../../resources/assets/fiigersLogo.png";
import React from "react";
import {Link} from "react-router-dom";

const Login = () => {

    return (
        <div className={'fi-signin-wrapper'}>

            <div className={'fi-card-signin'}>
                <div style={{height: '50px;'}}>
                    <img src={logo} alt='fiigers_logo' className='navigationBarLogo'></img>
                </div>

                {/*<h1 className={'fi-logo'}>fiigers</h1>*/}
                {/*<div className={'payrollLogo'}>Payroll</div>*/}
                <div className={'fi-signin-header'}>
                    <h2>Welcome to <span className={'payrollLogo'}>Payroll</span>!</h2>
                    <h4>Please sign in to continue</h4>
                    <form>
                        <div className="form-group">
                            <TitledInput type="text" title={"Email"}
                                   placeholder="Enter your email"/>
                            <TitledInput type="password" title={"Password"}
                                         placeholder="Enter your password"
                                         />

                            <div className={`formSubmit-blue`}
                                 onClick={()=>{}}>
                                Sign In
                            </div>
                            </div>
                    </form>

                </div>

                <div>Optionally,
                    {/*<a href='https://fiigers-authn.onrender.com/auth/google'>*/}
                    <a href='https://www.authn.fiigers.com/auth/google'>
                        <span className={"link"} style={{fontWeight:"bold"}}>Sign in with Google</span>
                    </a>
                    </div>

                <span style={{fontWeight:"bold", fontSize:"14px", marginTop:"15px"}} className={"link"}>Forgot password?</span>
                <span style={{fontSize:"14px"}}>Don't have an account?
                    <Link to={'/sign-up'}>
                    <span style={{fontWeight:"bold", marginLeft:"5px"}} className={"link"}>Create an Account</span>
                    </Link>
                    </span>

            </div>



        </div>
    )

}

export default Login;
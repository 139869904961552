import _ from 'lodash';
import TitledInput from "../common/TitledInput";
import TitledSelect from "../common/TitledSelect";
import {deductionTypeOptions, periodTypeOptions} from "../../data/Options";
import Checkbox from "../common/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {
    onChangeAmountInput, onChangeIsEditingDeduction, onChangeMaxPerYearInput,
    onChangeNameInput, onChangePeriodInput, onChangeRequiredInput, onChangeSelectedDeduction, onChangeTypeInput,
    onChangeSelectedComponentsInput
} from "../../redux/features/DedsAndContribsSlice";
import {toggleCreateDeductionModal} from "../../redux/features/DedsAndContribsSlice";
import {useState} from "react";
import {current} from "@reduxjs/toolkit";
import {submitAddDeductionFormFunction} from "./functions";

const CreateDeductionsModal = () => {
    const dispatch = useDispatch();
    const {
        addDeductionNameInput,
        addDeductionTypeInput,
        addDeductionAmountInput,
        addDeductionPeriodInput,
        addDeductionMaxPerYearInput,
        addDeductionRequiredInput,
        addDeductionSelectedComponentsInput,
        isEditingDeduction,


    } = useSelector((store) => store.deductionsAndContributions);
    const {componentSnapshot} = useSelector((store)=> store.snapshot);

    // load ids into local set from global state
    let ids = addDeductionSelectedComponentsInput.map(item => item.id);
    const initSet = new Set(ids);
    const [selectedComponentIds, setSelectedComponentIds] = useState(initSet);

    const updateAddName = (event) => {
        dispatch(onChangeNameInput(event.target.value));
    }
    const updateAddType = (state) => {
        dispatch(onChangeTypeInput(state));
    }
    const updateAddAmount = (event) => {
        dispatch(onChangeAmountInput(event.target.value));
    }
    const updateAddPeriod = (state) => {
        dispatch(onChangePeriodInput(state));
    }
    const updateAddMaxPerYear = (event) => {
        dispatch(onChangeMaxPerYearInput(event.target.value));
    }
    const updateRequiredInput = (state) => {
        dispatch(onChangeRequiredInput(!state));
    }
    const updateSelectedDeduction = (item) => {
        dispatch(onChangeIsEditingDeduction(true));
        dispatch(onChangeSelectedDeduction(item));
    }

    const submitForm = () => {
        submitAddDeductionFormFunction();
    }

    const saveChangesToDeduction = () => {
        // saveChangesToDeductionFunction();
    }

    const toggleModalPrompt = (input) => {
        dispatch(toggleCreateDeductionModal(!input));
    }

    const isChecked = (id) => {
        return selectedComponentIds.has(id);
    }
    const toggleSelection = (id, item) => {
        let currState = [...addDeductionSelectedComponentsInput];
        if (isChecked(id)) { // remove from local and global state
            currState = _.filter(currState, (item) => item.id != id);
            dispatch(onChangeSelectedComponentsInput(currState));
            selectedComponentIds.delete(id);
        } else { // add to local and global state
            currState.push(item);
            dispatch(onChangeSelectedComponentsInput(currState));
            selectedComponentIds.add(id);
        }
    }

    const renderComponents = () => {
        return componentSnapshot.map((item, index) =>
            <div className={'employeeCustomizerListTile ToOrderListItems'} style={{paddingLeft: 0}}
                 key={index}
            >
                <Checkbox value={isChecked(item.id)}
                          onClick={()=>{toggleSelection(item.id, item)}}/>
                <div className={'employeeCustomizerListTile-text'}>{item.payComponent}</div>

                <div className={'flex1'}/>
            </div>
        )
    }

    return (
        <div className={'createDeductionsBlocker'}>
            <div className={'createDeductionsBackground'} onClick={()=>{toggleModalPrompt(false)}}/>
            <div className={'createDeductionsModal horizontalFlex'}>
                <div className={'createModalForm flex1 verticalFlex'} style={{padding: '12px'}}>
                    <div className={'createDeductionTitle'}>Create Deduction</div>

                    <TitledInput title={'Name'} placeholder={'Name'} value={addDeductionNameInput}
                                 onChange={updateAddName}/>
                    <TitledSelect title={'Type'} options={deductionTypeOptions} value={addDeductionTypeInput}
                                  onChange={updateAddType}/>
                    <TitledInput title={'Amount'} placeholder={'Amount'} value={addDeductionAmountInput}
                                 onChange={updateAddAmount}/>
                    <TitledSelect title={'Period'} options={periodTypeOptions} value={addDeductionPeriodInput}
                                  onChange={updateAddPeriod}/>
                    <TitledInput title={'Max per year'} placeholder={'Max per year: defaults to unlimited'}
                                 value={addDeductionMaxPerYearInput}
                    onChange={updateAddMaxPerYear}/>



                    <div className={'flex1'}></div>
                    <div className={'horizontalFlex manageComponent-required-container'} style={{margin: '10px 0px'}}>
                        <Checkbox checked={true} title={'Required?'}
                                  value={addDeductionRequiredInput}
                                  onClick={updateRequiredInput}/>
                    </div>
                    <div className={'horizontalSplitter-20px'}/>
                    <div className={`formSubmit-green ${isEditingDeduction ? 'editing':''}`}
                         onClick={isEditingDeduction ? saveChangesToDeduction : submitForm}>
                        {isEditingDeduction ? "Save" : "+ Create"}
                    </div>



                </div>
                <div className={'createDeductionsComponentsHolder'} style={{padding: '12px'}}>
                    <div className={'createDeductionTitle'}>Components</div>
                    {renderComponents()}
                </div>

            </div>
        </div>
    );
}

export default CreateDeductionsModal;
import TitledInput from "../common/TitledInput";
import logo from "../../resources/assets/fiigersLogo.png";
import React, {useState} from "react";
import TitledSelect from "../common/TitledSelect";
import Checkbox from "../common/Checkbox";
import {Link} from "react-router-dom";

const SignUp = () => {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={'fi-signin-wrapper'}>

            <div className={'fi-card-signin'}>
                <div style={{height: '50px'}}>
                    <img src={logo} alt='fiigers_logo' className='navigationBarLogo'></img>
                </div>
                <div className={'fi-signin-header'}>
                    <h2>Sign up for <span className={'payrollLogo'}>Payroll</span></h2>
                    <form>
                        <div className="form-group">
                            <TitledInput type="text" title={"Email"}
                                         placeholder="Enter your email"/>
                            <TitledInput type={`${showPassword ? "text" : "password"}`} title={"Password"}
                                         placeholder="Enter your password"
                            />
                            <TitledInput type={`${showPassword ? "text" : "password"}`} title={"confirm password"}
                                         placeholder="Confirm your password"
                            />

                            <Checkbox title={"Show password"} onClick={()=>{setShowPassword(prevState => !prevState)}} value={showPassword==true}/>

                            <div className={`formSubmit-blue`}
                                 onClick={()=>{}}>
                                Sign Up
                            </div>
                        </div>
                    </form>

                </div>

                <div>Optionally, <span className={"link"} style={{fontWeight:"bold"}}>Sign up with Google</span></div>

                <span style={{fontSize:"14px"}}>Already have an account?
                    <Link to={'/sign-in'}>
                    <span style={{fontWeight:"bold", marginLeft:"5px"}} className={"link"}>Sign in instead</span>
                    </Link>
                    </span>
            </div>



        </div>
    )

}

export default SignUp;
export const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
];

export const businessTypeOptions = [
    {value: 'individual entity', label: 'individual entity'},
    {value: 'business entity', label: 'business entity'},
    {value: 'non-profit entity', label: 'non-profit entity'}
]

export const countryOptions = [
    {value: 'Cameroon', label: 'Cameroon'},
    {value: 'Egypt', label: 'Egypt'},
    {value: 'Ivory Cost', label: 'Ivory Cost'},
    {value: 'Kenya', label: 'Kenya'},
    {value: 'Malawi', label: 'Malawi'},
    {value: 'Mauritius', label: 'Mauritius'},
    {value: 'Nigeria', label: 'Nigeria'},
    {value: 'Rwanda', label: 'Rwanda'},
    {value: 'Senegal', label: 'Senegal'},
    {value: 'South Africa', label: 'South Africa'},
    {value: 'Tanzania', label: 'Tanzania'},
    {value: 'Uganda', label: 'Uganda'},
    {value: 'United Kingdom', label: 'United Kingdom'},
    {value: 'United States', label: 'United States'},
    {value: 'Rest of Europe', label: 'Rest of Europe'}
];

export const countryOptionsLimited = [
    {value: 'Uganda', label: 'Uganda'},
];


export const numberOfEmployeesOptions = [
    {value: 'Under 10 employees', label: 'Under 10 employees'},
    {value: '11 - 50 employees', label: '11 - 50 employees'},
    {value: '51 - 250 employees', label: '51 - 250 employees'},
    {value: '251 - 1000 employees', label: '251 - 1000 employees'},
    {value: '1000+ employees', label: '1000+ employees'}
]

export const industryOptions = [
    {value: 'Fintech and PSPs', label: 'Fintech and PSPs'},
    {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
    {value: 'Cryptocurrency & Blockchain', label: 'Cryptocurrency & Blockchain'},
    {value: 'Banks & Other Financial Institutions', label: 'Banks & Other Financial Institutions'},
    {value: 'Gaming', label: 'Gaming'},
    {value: 'Media & Entertainment', label: 'Media & Entertainment'},
    {value: 'Travel, Restaurants & Hospitality', label: 'Travel, Restaurants & Hospitality'},
    {value: 'Healthcare', label: 'Healthcare'},
    {value: 'Education', label: 'Education'},
    {value: 'Charities & NGOs', label: 'Charities & NGOs'},
    {value: 'Telecommunications & Internet Service Providers', label: 'Telecommunications & Internet Service Providers'},
    {value: 'Supply, Wholesale & Logistics', label: 'Supply, Wholesale & Logistics'},
    {value: 'Manufacturing', label: 'Manufacturing'},
    {value: 'Software & Technology', label: 'Software & Technology'},
    {value: 'Government Services', label: 'Government Services'},
    {value: 'Other', label: 'Other'},
];

export const employmentTypeOptions = [
    {value: 'employee_part_time', label: 'Employee: Part time'},
    {value: 'employee_fixed_term', label: 'Employee: Fixed term'},
    {value: 'employee_casual_worker', label: 'Employee: Casual worker'},
    {value: 'consultant', label: 'Consultant'},
    {value: 'contractor', label: 'Contractor'}
];

export const payTypeOptions = [
    {value: 'Hourly', label: 'Hourly'},
    {value: 'Salary', label: 'Salary'}
];

export const deductionTypeOptions = [
    {value: 'Fixed Amount', label: 'Fixed Amount'},
    {value: 'Percentage', label: 'Percentage'},
    {value: 'Progressive', label: 'Progressive'}
];

export const periodTypeOptions = [
    {value: 'pay period', label: 'pay period'},
    {value: 'month', label: 'month'},
    {value: 'year', label: 'year'}
];
import {
    appendDeductionToOrgSnapShot,
    appendPayComponentToOrgSnapShot,
    updateOrgSnapShot
} from "../../redux/features/SnapshotSlice";
import {onClearInputManageComponents} from "../../redux/features/MyOrgSlice";
import {onClearInputManageDeductions} from "../../redux/features/DedsAndContribsSlice";
import uuid from "uuid-random";

let store;
export const injectStoreInDeductionsAndContributionsFunctions = _store => {
    store = _store;
}

export const submitAddDeductionFormFunction = () => {
    // make call to api
    // on successful api call, update the snapshot
    let data = store.getState().deductionsAndContributions;
    let data_ = packageDeductionInputsForOrgSnapshot(data);

    store.dispatch(appendDeductionToOrgSnapShot(data_));
    store.dispatch(onClearInputManageDeductions());
}


const packageDeductionInputsForOrgSnapshot = (data) => {
        return {
            id: !data.selectedDeduction || !data.selectedDeduction.id ? uuid() : data.selectedDeduction.id,
            name: data.addDeductionNameInput,
            type: data.addDeductionTypeInput,
            amount: data.addDeductionAmountInput,
            period: data.addDeductionPeriodInput,
            maxPerYear: data.addDeductionMaxPerYearInput,
            required: data.addDeductionRequiredInput,
            payComponents: data.addDeductionSelectedComponentsInput,
        }
}
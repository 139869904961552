import {BarsIcon} from "../../resources/icons";

const MoreIcon = (props) => {
    return (
        <div className={'moreIcon'} onClick={props.onClick}>
            <BarsIcon/>
        </div>
    )
}

export default MoreIcon;
import Checkbox from "../common/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {onChangeAddTagDescriptionInput,
    onChangeAddTagInput, onChangeAddTagRequiredInput,
    onChangeIsEditingTag, onChangeSelectedTag
} from "../../redux/features/MyOrgSlice";
import {
    saveChangesToTagFunction,
    submitAddTagFormFunction
} from "./functions";
import RowTile from "../common/RowTile";

const ManageTags =()=>{

    const dispatch = useDispatch();

    const {
        addTagInput,
        addTagRequiredInput,
        addTagDescriptionInput,
        isEditingTag,
        selectedTag
    } = useSelector((store) => store.myOrg);
    const {tagSnapshot} = useSelector((store)=> store.snapshot);

    const updateAddTag = (event) => {
        dispatch(onChangeAddTagInput(event.target.value));
    }
    const updateAddTagRequired = (state) => {
        dispatch(onChangeAddTagRequiredInput(!state));
    }
    const updateAddTagDescription = (event) => {
        dispatch(onChangeAddTagDescriptionInput(event.target.value));
    }
    const updateIsEditingTag = (state) => {
        dispatch(onChangeIsEditingTag(!state));
    }
    const updateSelectedTag = (item) => {
        dispatch(onChangeIsEditingTag(true));
        dispatch(onChangeSelectedTag(item));
    }

    const submitForm = () => {
        submitAddTagFormFunction();
    }

    const saveChangesToTag = () => {
        saveChangesToTagFunction();
    }

    const renderRowTiles = () => {
        let data_ = tagSnapshot;
        return data_.map( item =>
            <RowTile required={item.required} label={item.tag}
                     description={item.description} onClickEdit={()=>{updateSelectedTag(item)}}/>
        );
    }
    return (
        <div>
            <div className={'horizontalFlex darkContainer'}>
                <div className={'verticalFlex'}>
                    <div className={'input-manageComponents-add-container'}>
                        <div className={'input-addComponent white-background border-radius-inherit'}>
                            <input
                                className={'input'}
                                placeholder={'Add Tag'}
                                value={addTagInput}
                                onChange={updateAddTag}
                            />
                        </div>
                    </div>
                    <div className={'verSplitter-10px'}></div>
                    <div className={`formSubmit-green ${isEditingTag ? 'editing':''}`}
                         onClick={isEditingTag ? saveChangesToTag : submitForm}>
                        {isEditingTag ? "Save" : "+ Add"}
                    </div>

                    <div className={'flex1'}></div>
                    <div className={'flex1 horizontalFlex manageComponent-required-container'}>
                        <Checkbox checked={true} title={'Required?'}
                                  value={addTagRequiredInput}
                                  onClick={updateAddTagRequired}/>
                    </div>

                </div>
                <div className={'flex1 white-background textarea-description'}>
                    <textarea
                        className={'textarea'}
                        placeholder={'Optional: description'}
                        value={addTagDescriptionInput}
                        onChange={updateAddTagDescription}
                    />
                </div>
            </div>
            <div className={'bdx-100'}></div>
            {renderRowTiles()}

            {/*NIN NSSF ID TIN ORG_ID*/}
            <div className={'listTile'}/>
            <div className={'listTile'}/>
            <div className={'listTile'}/>
        </div>
    )
}
export default ManageTags;
const ManageEmployees =()=>{

        // TODO delete during clean up
        const data_ = [
                {
                        id: "1231-3213123",
                        fName: "andrew",
                        lName: "ebong",
                        email: "ebongandrew@clairebutton.com",
                        earnings: 900000,
                        localDeductions: 300000,
                        netPay: 600000,
                        employmentType: "Employee",
                        payType: "Salaried",
                        rate: "900000 per month"
                },
                {
                        id: "1231-3213122",
                        fName: "glander",
                        lName: "madondo",
                        email: "glander.m@clairebutton.com",
                        earnings: 1200000,
                        localDeductions: 200000,
                        netPay: 1000000,
                        employmentType: "Contractor",
                        payType: "Salaried",
                        rate: "1200000 per month"
                },
                {
                        id: "1231-3213125",
                        fName: "laura",
                        lName: "kemiteto",
                        email: "laurak@clairebutton.com",
                        earnings: 1900000,
                        localDeductions: 300000,
                        netPay: 1600000,
                        employmentType: "Employee",
                        payType: "Salaried",
                        rate: "1900000 per month"
                },
                {
                        id: "1231-3213129",
                        fName: "michael",
                        lName: "noah",
                        email: "michael.n@clairebutton.com",
                        earnings: 2900000,
                        localDeductions: 300000,
                        netPay: 2600000,
                        employmentType: "Contractor",
                        payType: "Hourly",
                        rate: "18125 per hour"
                },
                {
                        id: "1231-321312323",
                        fName: "conrad",
                        lName: "amai",
                        email: "conrad.a@clairebutton.com",
                        earnings: 1900000,
                        localDeductions: 300000,
                        netPay: 1600000,
                        employmentType: "Contractor",
                        payType: "Hourly",
                        rate: "11875 per hour"
                }
        ];

        const renderItems = () => {
                return data_.map(item =>
                    <div className={'listTile2'}>
                            <div className={'profileHolder'}>{`${item.fName[0]}${item.lName[0]}`}</div>
                            <div className={'flex1 capitalize'}>{`${item.fName} ${item.lName}`}</div>
                            <div className={'flex1'}>{item.employmentType}</div>
                            <div className={'flex1'}>{item.payType}</div>
                            <div className={'flex1'}>{item.rate}</div>
                            <div className={'flex1'}>
                                    <div className={'flex1'}/>
                                    <div className={'horizontalFlex'}>
                                            <div className={'listTile-component-buttons pointer'}>
                                                    <div className={'button'}>
                                                            Sleep
                                                    </div>
                                                    <div className={'splitter-xsd'}/>
                                                    <div className={'button'}>
                                                            Edit
                                                    </div>
                                                    <div className={'splitter-xsd'}/>
                                                    <div className={'button'}>Delete</div>
                                            </div>
                                    </div>
                            </div>
                    </div>
                )
        }

    return (
        <div>
            <div className={'manageEmployeesTopBar'}>
                    <div style={{width: 35, marginRight: 15}}/>
                    <div className={'flex1'}>Name</div>
                    <div className={'flex1'}>Employment Type</div>
                    <div className={'flex1'}>Pay Type</div>
                    <div className={'flex1'}>Rate</div>
                    <div className={'flex1'}></div>
            </div>
            <div className={'verSplitter-10px'}/>
            <div className={'horizontalLine'}/>

                {renderItems()}
            <div className={'listTile2'}></div>
            <div className={'listTile2'}></div>
            <div className={'listTile2'}></div>


        </div>
    )
}

export default ManageEmployees;
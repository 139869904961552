const TitledInput =(props)=> {
    return (
        <div className={'titled-input'}>
            <div className={`input-title ${props.userDefined? 'userDefined': ''}`}>{props.title}</div>
            <div className={'input-holder'}>
                <input className={'input'}
                       value={props.value}
                       placeholder={props.placeholder}
                       onChange={props.onChange}
                       type={props.type ? props.type : "text"}
                />
            </div>
        </div>
    )
}

export default TitledInput;
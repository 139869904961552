import {forwardRef, useState} from "react";
import DatePicker from "react-datepicker";

const DatePickerInput = (props) => {
    //TODO can pass props.date to use as date
    const [startDate, setStartDate] = useState(new Date());
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="datepicker-custom-input" onClick={onClick} ref={ref}
             style={{display: "flex", flex:"1", backgroundColor:"blue", height: "40px", alignItems:'center'}}>
            {value}
        </div>
    ));


    return (
            <DatePicker
                showIcon
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                // customInput={<CustomInput />}
                inline
            />
        );
}


export default DatePickerInput;
import {createSlice} from "@reduxjs/toolkit";
import {
    SIDE_NAV_HOME_DASHBOARD,
    SIDE_NAV_PAYROLL_RUN_PAYROLL,
    TOP_NAV_HOME,
    TOP_NAV_PAYROLL
} from "../../functions/constants";

let initialState = () => {
    return {
        activePageTitle: "Payroll",
        activeTopNavigation: TOP_NAV_HOME,
        activeSideNavigation: SIDE_NAV_HOME_DASHBOARD
    }
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },
        setTopNavigation: (state, action) => {
            state.activeTopNavigation = action.payload;
        },
        setSideNavigation: (state, action) => {
            state.activeSideNavigation = action.payload;
        }
    }
});

export const {setPageTitle, setSideNavigation, setTopNavigation} = navigationSlice.actions;
export default navigationSlice.reducer;
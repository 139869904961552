import {detailsViewSelector, GENERAL_INFO} from "./functions";
import _ from "lodash";
import {users} from "../../resources/dirtyContext/dirtyUsers";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedEmployee} from "../../redux/features/LocalStateSlice";
import employeeComponents from '../../resources/employeeComponents.json';
import {evaluate} from "mathjs";
import {SearchIcon} from "../../resources/icons";

const EmployeesDetails = () => {
    // Tab constants
    const GENERAL_INFO = "general";
    const PERSONAL_INFO = "personal";
    const IDENTIFICATION_INFO = "identification";
    const EMPLOYMENT_INFO = "employmentInfo";
    const PAY_INFO = "payInfo";
    const OTHER_INFO = "otherInfo";
    const dispatch = useDispatch();
    const {selectedEmployee} = useSelector((store) => store.localState);
    const {employeesSnapshot} = useSelector(store => store.snapshot);
    let employeeName = selectedEmployee.generalInfo.fName + " " + selectedEmployee.generalInfo.lName || "";
    const [searchString, setSearchString] = useState(employeeName);
    const [activeTab, setActiveTab] = useState(GENERAL_INFO);


    // otherwise, use snapshot
    let data_ = selectedEmployee;

    let values_ = detailsViewSelector(GENERAL_INFO);

    // let renderElems = (arr) => {
    //     const objList = _.keyBy(users, 'id');
    //     const obj = objList[data_.selectedUser]
    //     return arr.map (item => {
    //         let val = _.get(obj, item.path, "");
    //         return <div className={'employeeDetails-tile'}>
    //             <div className={'employmentDetails-tile-title'}>{item.label}</div>
    //             <div className={'employmentDetails-tile-value'}>{val}</div>
    //         </div>
    //     })
    //
    // }

    const getValueFromPath = (obj, elem) => {
        let path = elem.path;
        let operationObj = elem.operation;
        if (operationObj) {
            if (operationObj.type == "TEXT_JOIN") {
                // loop through arr and join text
                if (operationObj.fromArrayObject) { // if object is in array, select the primary
                    let arr = _.get(obj, operationObj.fromArrayObject, "");
                    obj = _.find(arr,(item) => { // extract primary from object
                        return item.primary;
                    });
                }
                return _.reduce(operationObj.value, (concat, value) => {
                    return concat + " " + _.get(obj, value, "");
                }, "");
            } else if (operationObj.type == "EVAL") {
                let toEvaluate = _.reduce(operationObj.value, (concat, value) => {
                    if (value.charAt(0) == "%") {
                        return concat + " " + value.charAt(1);
                    }
                    return concat + " " + _.get(obj, value, "0");
                }, "");
                return evaluate(toEvaluate);
            }
            return "";
        }
        if (elem.fromArrayObject) { // if object is in array, select the primary
            let arr = _.get(obj, elem.fromArrayObject, "");
            obj = _.find(arr,(item) => { // extract primary from object
                return item.primary && item.primary || item;
            });
        }
        // let path = prefOrDefaults[index].path;
        return _.get(obj, path, "");
    }

    const getRootObject = (selection) => {
        switch (selection) {
            case GENERAL_INFO:
                return "generalInfo"
            case PERSONAL_INFO:
                return "personalInfo"
            case IDENTIFICATION_INFO:
                return "identificationInfo"
            case EMPLOYMENT_INFO:
                return "employmentInfo"
            case PAY_INFO:
                return "payInfo"
            case OTHER_INFO:
                return "others"

                // const  = "employmentInfo";
                // const  = "payInfo";
                // const OTHER_INFO
        }
    }

    const renderPageDetails = () => {
        let obj = employeeComponents[getRootObject(activeTab)];
        // for each element in the object
        // print out the label and value
       return _.map(obj, (elem) => {
            // let val = _.get(selectedEmployee, elem.path, "");
            let val = getValueFromPath(selectedEmployee, elem)
            return <div className={'employeeDetails-tile'}>
                <div className={'employmentDetails-tile-title'}>{elem.label}</div>
                <div className={'employmentDetails-tile-value'}>{val}</div>
            </div>
        })
    }

    const selectEmployee = (emp) => {
        dispatch(setSelectedEmployee(emp));
    }
    const classIfActive = (elem) =>{
        return activeTab == elem ? "active": "";
    }

    const renderEmployeeList = () => {
        let searchString_ = searchString.toLowerCase();
        let searchingData = searchString_ && _.filter(employeesSnapshot, (employee) => {
            return employee.generalInfo.fName.toLowerCase().includes(searchString) ||
                employee.generalInfo.lName.toLowerCase().includes(searchString) ||
                (employee.generalInfo.fName.toLowerCase() + " " + employee.generalInfo.lName.toLowerCase()).includes(searchString) ||
                (employee.generalInfo.lName.toLowerCase() + " " + employee.generalInfo.fName.toLowerCase()).includes(searchString) ||
                employee.employmentInfo.employmentType.toLowerCase().includes(searchString) ||
                employee.employmentInfo.payType.toLowerCase().includes(searchString);

        })
        // otherwise, use snapshot

        let data = searchingData && searchString_ ? searchingData : employeesSnapshot;
        return data && data.map(employee =>
            <div className={`employeeDList-tile ${ selectedEmployee.id == employee.id ? 'active' : ''}`}
                 onClick={()=>{selectEmployee(employee)}}>
                <div className={'companyLogoContainer employeeProfilePicture'} >{employee.generalInfo.fName[0]}{employee.generalInfo.lName[0]}</div>
                <div className={'verticalFlex flex1'}>
                    <div style={{fontWeight:'bold'}}>{`${employee.generalInfo.fName} ${employee.generalInfo.lName}`} </div>
                    <div style={{color:'gray'}}>aebong@clairebutton.com</div>
                    <div style={{marginTop:'5px', fontSize:'15px'}}>{`${employee.employmentInfo.employmentType} |
                        ${employee.employmentInfo.payType}`}</div>
                    <div></div>
                </div>
            </div>
        )
    }
    return (
        <div className={'employeeDetails-mother'}>
            <div className={'employeeDetails-sideBar'}>

                <div className={'employeeDListHeader'}>
                    <div className={'employeeDListTitle'}>
                        Employees
                    </div>
                    <div className={'flex1'}/>
                    <div className={'employeeDListSearchHolder'}>
                        <SearchIcon/>
                        <input className={'input'}
                               placeholder={'Search Employees'}
                               value={searchString}
                               onChange={event=>setSearchString(event.target.value)}
                        />
                    </div>

                </div>
                <div className={'employeeDetails-sideBar-body'}>
                    {renderEmployeeList()}
                    <div className={'employeeDList-tile'}></div>
                    <div className={'employeeDList-tile'}></div>
                </div>
            </div>
            <div className={'employeeDetails-daughter'}>

                <div style={{display:'flex', minHeight: '140px', margin: '0px 80px', alignItems:'center'}}>
                    <div className={'contactProfileHolder'}>
                        {`${selectedEmployee && selectedEmployee.generalInfo.fName[0]}${selectedEmployee && selectedEmployee.generalInfo.lName[0]}`}</div>
                    <div className={'employeeDetails-card'}>
                        <div className={'employeeDetails-superName'}>
                            {`${selectedEmployee && selectedEmployee.generalInfo.fName}
                             ${selectedEmployee && selectedEmployee.generalInfo.lName}`}</div>
                        <div className={'employeeDetails-superEmail'}>{selectedEmployee && selectedEmployee.generalInfo.email}</div>
                    </div>
                </div>
                <div className={'employeeDetails-tabSelector'}>
                    <div className={`employeeDetails-tab ${classIfActive(GENERAL_INFO)}`} onClick={()=>{setActiveTab(GENERAL_INFO)}}>General</div>
                    <div className={`employeeDetails-tab ${classIfActive(PERSONAL_INFO)}`} onClick={()=>{setActiveTab(PERSONAL_INFO)}}>Personal</div>
                    <div className={`employeeDetails-tab ${classIfActive(IDENTIFICATION_INFO)}`} onClick={()=>{setActiveTab(IDENTIFICATION_INFO)}}>Identification</div>
                    <div className={`employeeDetails-tab ${classIfActive(EMPLOYMENT_INFO)}`} onClick={()=>{setActiveTab(EMPLOYMENT_INFO)}}>Employment</div>
                    <div className={`employeeDetails-tab ${classIfActive(PAY_INFO)}`} onClick={()=>{setActiveTab(PAY_INFO)}}>Pay</div>
                    <div className={`employeeDetails-tab ${classIfActive(OTHER_INFO)}`} onClick={()=>{setActiveTab(OTHER_INFO)}}>Others</div>
                </div>
                <div className={'employeeDetails-body'}>
                    {renderPageDetails()}
                    {/*{JSON.stringify(selectedEmployee)}*/}
                    {/*{renderElems(values_)}*/}
                </div>


            </div>

        </div>
    );
}

export default EmployeesDetails;
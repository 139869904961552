import Select from "react-select";

const TitledSelect =(props)=>{
    return (
        <div className={'titled-input'}>
            {props.title && <div className={'input-title'}>{props.title}</div>}
            <div className={'input-holder'}>
                <Select  className={'customSelectionStyling'}
                         options={props.options} classNamePrefix="rs"
                         value={props.value}
                         onChange={props.onChange}
                />
            </div>
        </div>
    )
}

export default TitledSelect;
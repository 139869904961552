import {AtIcon, PhoneIcon, WWWIcon} from "../../resources/icons";
import {useDispatch, useSelector} from "react-redux";

const ViewOrg =(props)=>{
    // const dispatch = useDispatch();

    // companyName: obj.companyNameInput,
    //     emailAddress: obj.emailAddressInput,
    //     phoneNumber: obj.phoneNumberInput,
    //     businessType: obj.businessTypeInput,
    //     countryBusinessIsRegistered: obj.countryBusinessIsRegisteredInput,
    //     countryOfOperationForThisEntity: obj.countryOfOperationForThisEntityInput,
    //     industry: obj.industryInput,
    //     numberOfEmployees: obj.numberOfEmployeesInput,
    //     businessWebsite: obj.businessWebsiteInput,
    //     businessSocialMediaLink: obj.businessSocialMediaLinkInput


    const {orgSnapshot} = useSelector((store)=> store.snapshot);
    return (

        <div className={'viewOrg'}>
            <div className={'myOrg-banner'}>
                <div className={'myOrg-banner-daughter'}>
                    <div className={'title'}>{orgSnapshot.companyName || "Name not set"}</div>
                    <div className={'links'}>
                        <AtIcon/>
                        <div className={'email'}>
                            <a href = {`mailto: ${orgSnapshot.emailAddress}`}>{orgSnapshot.emailAddress || "email not set"}</a>
                        </div>
                        <div className={'horizontalSplitter-10px'}></div>
                        <WWWIcon/>
                        <div className={'website-social'}>
                            <a href={`${orgSnapshot.businessWebsite}`} target="_blank">{orgSnapshot.businessWebsite || "website not set"}</a>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'manageOrg-container'} style={{marginTop:'0px'}}>

            <div className={'body'} style={{marginTop:'0px'}}>
                <div className={'addressCard'}>
                    <div className={'content'} style={{backgroundColor:'#fff'}}>
                        <div style={{fontWeight: 'bold', marginBottom: '10px'}}>Address</div>
                        <div className={'addressLine'}>{orgSnapshot.address1 || ""}</div>
                        <div className={'addressLine'}>{orgSnapshot.address2 || ""}</div>
                        <div className={'addressLine'}>{orgSnapshot.city || ""}</div>
                        <div className={'addressLine'}>{orgSnapshot.state || ""}</div>
                        <div className={'addressLine'}>{orgSnapshot.country && orgSnapshot.country.label || ""}</div>
                        <div className={'addressLine'}>{orgSnapshot.postalCode || ""}</div>
                        { !orgSnapshot.address1 && !orgSnapshot.address2 && !orgSnapshot.city && !orgSnapshot.state &&
                            !orgSnapshot.country && !orgSnapshot.postalCode && "address not set"

                        }
                    </div>
                    <div className={'contact'} style={{backgroundColor:'#fff'}}>
                        <PhoneIcon/>
                        <div className={'contact-content'}>{orgSnapshot.phoneNumber || "phone not set"}</div>
                    </div>

                </div>
                <div className={'addInf'} style={{backgroundColor:'#fff'}}>
                    <div className={'label'}>Additional Information</div>
                    <div className={'title'}>Business Type</div>
                    <div className={'content'}>{orgSnapshot.businessType && orgSnapshot.businessType.label || "business type not set"}</div>
                    <div className={'title'}>Country business is registered</div>
                    <div className={'content'}>{orgSnapshot.countryBusinessIsRegistered && orgSnapshot.countryBusinessIsRegistered.label || "country not set"}</div>
                    <div className={'title'}>Country of operation for this entity</div>
                    <div className={'content'}>{orgSnapshot.countryOfOperationForThisEntity && orgSnapshot.countryOfOperationForThisEntity.label || "country not set"}</div>
                    <div className={'title'}>Industry</div>
                    <div className={'content'}>{orgSnapshot.industry && orgSnapshot.industry.label || "industry not set"}</div>
                    <div className={'title'}>Number of employees</div>
                    <div className={'content'}>{orgSnapshot.numberOfEmployees && orgSnapshot.numberOfEmployees.label || "number of employees not set"}</div>
                    <div className={'verSplitter-10px'}/>
                    <div className={'verSplitter-10px'}/>

                    <div className={'label'}>Operational Information</div>



                </div>
            </div>
        </div>
        </div>
    )
}

export default ViewOrg;
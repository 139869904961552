import {
    CONTRIBUTIONS_ICON,
    DASHBOARD_ICON, DEDUCTIONS_ICON,
    HISTORY_ICON, OFFICE_ICON,
    PAY_CONTRACTORS_ICON,
    PEOPLE_ICON, PIE_CHART_ICON,
    RUN_PAYROLL_ICON,
    SETTINGS_ICON,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_CONTRIBUTIONS,
    SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_DEDUCTIONS,
    SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES, SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES,
    SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES,
    SIDE_NAV_HOME_DASHBOARD,
    SIDE_NAV_MY_ORG_MANAGE_ORG,
    SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS,
    SIDE_NAV_MY_ORG_MANAGE_TAGS,
    SIDE_NAV_MY_ORG_VIEW_ORG,
    SIDE_NAV_PAYROLL_HISTORY,
    SIDE_NAV_PAYROLL_PAY_CONTRACTORS,
    SIDE_NAV_PAYROLL_RUN_PAYROLL,
    SIDE_NAV_PAYROLL_SETTINGS, SQUARE_3_STACK_ICON, SQUARE_3_STACK_ICON_SOLID, TAG_ICON,
    USER_PLUS_ICON
} from "./constants";

export const payRollTabs = [
    {
        "title": "Run payroll",
        "icon": RUN_PAYROLL_ICON,
        "name": SIDE_NAV_PAYROLL_RUN_PAYROLL
    },
    {
        "title": "History",
        "icon": HISTORY_ICON,
        "name": SIDE_NAV_PAYROLL_HISTORY
    },


    // {
    //     "title": "Pay Contractors",
    //     "icon": PAY_CONTRACTORS_ICON,
    //     "name": SIDE_NAV_PAYROLL_PAY_CONTRACTORS
    // },
    // {
    //     "title": "History",
    //     "icon": HISTORY_ICON,
    //     "name": SIDE_NAV_PAYROLL_HISTORY
    // },
    // {
    //     "title": "Settings",
    //     "icon": SETTINGS_ICON,
    //     "name": SIDE_NAV_PAYROLL_SETTINGS
    // }
];

export const employeeTabs = [
    {
        "title": "View employees",
        "icon": PEOPLE_ICON,
        "name": SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES
    },
    {
        "title": "Add employees",
        "icon": USER_PLUS_ICON,
        "name": SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES
    },
    {
        "title": "Manage employees",
        "icon": SQUARE_3_STACK_ICON,
        "name": SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES
    }
];


// my org : view organisation, manage org, manage pay components, manage tags (extra fields associated with user)
export const myOrgTabs = [
    {
        "title": "View Organisation",
        "icon": OFFICE_ICON,
        "name": SIDE_NAV_MY_ORG_VIEW_ORG
    },
    {
        "title": "Manage Organisation",
        "icon": SQUARE_3_STACK_ICON,
        "name": SIDE_NAV_MY_ORG_MANAGE_ORG
    },
    {
        "title": "Manage pay Components",
        "icon": PIE_CHART_ICON,
        "name": SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS
    },
    {
        "title": "Manage Tags",
        "icon": TAG_ICON,
        "name": SIDE_NAV_MY_ORG_MANAGE_TAGS
    }
]

// deductions & contributions: view deductions, view contributions, manage deductions, manage contributions
export const deductionsAndContributionsTabs = [
    // {
    //     "title": "View Deductions",
    //     "icon": DEDUCTIONS_ICON, //TODO change icon
    //     "name": SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_DEDUCTIONS
    // },
    // {
    //     "title": "View Contributions",
    //     "icon": CONTRIBUTIONS_ICON, //TODO change icon
    //     "name": SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_CONTRIBUTIONS
    // },
    {
        "title": "Manage Deductions",
        "icon": DEDUCTIONS_ICON, //TODO change icon SQUARE_3_STACK_ICON
        "name": SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS
    },
    {
        "title": "Manage Contributions",
        "icon": CONTRIBUTIONS_ICON, //TODO change icon SQUARE_3_STACK_ICON_SOLID
        "name": SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS
    }
]

// home: dashboard,
export const homeTabs = [
    {
        "title": "Dashboard",
        "icon": DASHBOARD_ICON,
        "name": SIDE_NAV_HOME_DASHBOARD
    }
]

/*
* Group constants based on components. If grouping does not exist, create it and add to the order below for easy finding
* 1. TOP NAVIGATION CONSTANTS
* 2. SIDE NAVIGATION CONSTANTS
* 3. ICON CONSTANTS
* 4. STRING NAME CONSTANTS
* */


// 1. TOP NAVIGATION CONSTANTS
export const TOP_NAV_HOME = "topNavHome";
export const TOP_NAV_PAYROLL = "topNavPayroll";
export const TOP_NAV_EMPLOYEES = "topNavEmployees";
export const TOP_NAV_MY_ORG = "topNavMyOrg";
export const TOP_NAV_DEDUCTIONS_AND_CONTRIBUTIONS = "topNavTaxesAndDeductions";

// 2. SIDE NAVIGATION CONSTANTS

export const SIDE_NAV_PAYROLL_RUN_PAYROLL = "sideNavPayrollRunPayroll";
export const SIDE_NAV_PAYROLL_PAY_CONTRACTORS = "sideNavPayrollPayContractors";
export const SIDE_NAV_PAYROLL_HISTORY = "sideNavPayrollHistory";
export const SIDE_NAV_PAYROLL_SETTINGS = "sideNavPayrollSettings";

export const SIDE_NAV_HOME_DASHBOARD = "sideNavHomeDashboard";

export const SIDE_NAV_EMPLOYEES_VIEW_EMPLOYEES = "sideNavEmployeesViewEmployees";
export const SIDE_NAV_EMPLOYEES_ADD_EMPLOYEES = "sideNavEmployeesAddEmployees";
export const SIDE_NAV_EMPLOYEES_MANAGE_EMPLOYEES = "sideNavEmployeesManageEmployees";

export const SIDE_NAV_MY_ORG_VIEW_ORG = "sideNavMyOrgViewOrg";
export const SIDE_NAV_MY_ORG_MANAGE_ORG = "sideNavMyOrgManageOrg";
export const SIDE_NAV_MY_ORG_MANAGE_PAY_COMPONENTS = "sideNavMyOrgManagePayComponents";
export const SIDE_NAV_MY_ORG_MANAGE_TAGS = "sideNavMyOrgManageTags";

export const SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_DEDUCTIONS = "sideNavDeductionsAndContributionsViewDeductions";
export const SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_VIEW_CONTRIBUTIONS = "sideNavDeductionsAndContributionsViewContributions";
export const SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_DEDUCTIONS = "sideNavDeductionsAndContributionsManageDeductions";
export const SIDE_NAV_DEDUCTIONS_AND_CONTRIBUTIONS_MANAGE_CONTRIBUTIONS = "sideNavDeductionsAndContributionsManageContributions";


// 3. ICON CONSTANTS
export const HOME_ICON = "homeIcon";
export const PAYROLL_ICON = "payrollIcon";
export const PEOPLE_ICON = "peopleIcon";
export const OFFICE_ICON = "officeIcon";
export const DEDUCTIONS_ICON = "deductionsIcon";
export const CONTRIBUTIONS_ICON = "contributionsIcon";

export const RUN_PAYROLL_ICON = "runPayrollIcon";
export const PAY_CONTRACTORS_ICON = "payContractorsIcon";
export const HISTORY_ICON = "historyIcon";
export const SETTINGS_ICON = "settingsIcon";

export const DASHBOARD_ICON = "dashboardIcon";

export const USER_PLUS_ICON = "userPlusIcon";

export const PIE_CHART_ICON = "pieChartIcon";
export const TAG_ICON = "tagIcon";
export const SQUARE_3_STACK_ICON = "square3StackIcon";
export const SQUARE_3_STACK_ICON_SOLID = "square3StackIconSolid";


// 4. STRING NAME CONSTANTS
export const STRING_HOME = "home";
export const STRING_PAYROLL = "payroll";
export const STRING_EMPLOYEES = "employees";
export const STRING_MY_ORG = "my org";
export const STRING_DEDUCTIONS_AND_CONTRIBUTIONS = "Deductions & Contributions";

export const STRING_RUN_PAYROLL = "run payroll";
export const STRING_PAY_CONTRACTORS = "pay contractors";
export const STRING_HISTORY = "history";
export const STRING_SETTINGS = "settings";

export const STRING_DASHBOARD = "dashboard";
export const STRING_VIEW_EMPLOYEES = "view employees";
export const STRING_ADD_EMPLOYEES = "add employees";
export const STRING_MANAGE_EMPLOYEES = "manage employees";

export const STRING_VIEW_ORG = "view organisation";
export const STRING_MANAGE_ORG = "manage organisation";
export const STRING_MANAGE_PAY_COMPONENTS = "manage pay components";
export const STRING_MANAGE_TAGS = "manage tags";

export const STRING_VIEW_DEDUCTIONS = "view deductions";
export const STRING_VIEW_CONTRIBUTIONS = "view contributions";
export const STRING_MANAGE_DEDUCTIONS = "manage deductions";
export const STRING_MANAGE_CONTRIBUTIONS = "manage contributions";




import EmployeeDetailsOld from "../employees/EmployeeDetailsOld";
import EmployeesDetails from "../employees/EmployeesDetails";
import {useDispatch, useSelector} from "react-redux";
import {toggleMotherLoader} from "../../redux/features/LocalStateSlice";
import RunPayrollDetails from "../payroll/RunPayrollDetails";

const MotherBlocker = () => {
    const dispatch = useDispatch();
    const {currentMotherLoaderSpec} = useSelector(store => store.localState);
    const close = () => {
        dispatch(toggleMotherLoader(true))
    }
    return (
        <div className={'motherBlocker'}>
            <div className={'motherBlocker-daughter'}>
                <div className={'motherBlocker-header'}>
                    <div className={'motherBlocker-header-title'}>{currentMotherLoaderSpec && currentMotherLoaderSpec.title || "Run Payroll"}</div>
                    <div className={'flex1'}></div>
                    <div className={'motherBlocker-header-close'} onClick={close}>Close</div>
                </div>
                <div className={'motherBlocker-body'}>
                    {/*<RunPayrollDetails/>*/}
                    <EmployeesDetails/>
                </div>
                <div className={'motherBlocker-footer'}></div>
            </div>
        </div>
    );
}
export default MotherBlocker;